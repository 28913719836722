import { ApiManager, ApiManagerModel, HttpClient } from '@/core'
import { GetApprovalContractsModel } from './GetApprovalContractsModel'
import { ApiState } from '@/models/ApiState'
import { useQuery, UseQueryOptions } from 'react-query'
import { adapter } from './adapters/adapter'
import { useAppContext } from '@/core'
import { AppContext } from '@/models/AppContext'

export const getApprovalContracts: GetApprovalContractsModel.Func = async (props) => {
  const { path, control } = ApiManager.getState<ApiState, ApiManagerModel.ApiOption>(
    (state) => state.approvalContracts.getApprovalContracts,
    {} as ApiManagerModel.ApiOption
  )

  const response = await HttpClient.post<
    GetApprovalContractsModel.ResponseData,
    void,
    GetApprovalContractsModel.RequestData,
    GetApprovalContractsModel.RequestHeaders,
    GetApprovalContractsModel.ErrorResponse
  >({
    url: path,
    data: { ApprovalContractsType: props.approvalContractsType },
    headers: {
      _ver_: props.apiVersion,
      _os_: '4',
    },
    responseAdapter: adapter,
    control,
  })

  return response
}

function settingsKey() {
  return '/api/approvalContracts/getApprovalContracts'
}

export function useGetApprovalContractsQuery<T = GetApprovalContractsModel.ResponseData>(
  props: GetApprovalContractsModel.Props,
  options: Omit<
    UseQueryOptions<
      GetApprovalContractsModel.ResponseData,
      GetApprovalContractsModel.ErrorResponse,
      T
    >,
    'queryKey' | 'queryFn'
  > = {}
) {
  const context = useAppContext<AppContext>()
  const key = settingsKey()
  const result = useQuery(
    key,
    async () =>
      getApprovalContracts({ ...props, apiVersion: context.config.apiVersion }).then(
        (res) => res.data
      ),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      retry: 1,
      ...options,
    }
  )
  return result
}
