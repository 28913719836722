import { block } from '@/routes/rest/examples/block'
import styled from '@emotion/styled'
import { Button } from '@platform-ui/components'
import { ITheme } from '@platform-ui/core'

export const Root = styled.div<{
  textColor: keyof ITheme['color'] | string
  bgColor: keyof ITheme['color'] | string
}>(({ theme, textColor, bgColor }) => ({
  display: 'block',
  borderRadius: theme.borderRadius.r10,
  padding: '16px',
  textDecoration: 'none',
  color: theme.color[textColor] || textColor,
  background: theme.color[bgColor] || bgColor,
  cursor: 'pointer',

  [`${theme.media.md}`]: {
    padding: '20px',
  },
}))

export const InfoSlotWrapper = styled.div<{
  borderColor: keyof ITheme['color'] | string
  textColor: keyof ITheme['color'] | string
}>(({ theme, textColor, borderColor }) => ({
  marginTop: '12px',
  borderTop: '1px solid transparent',
  borderColor: theme.color[borderColor] || borderColor,
  color: theme.color[textColor] || textColor,

  [`${theme.media.md}`]: {
    marginTop: '16px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}))

export const Content = styled.div(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}))

export const ContentLeft = styled.div(({ theme }) => ({
  width: '100%',
  marginLeft: 12,
}))

export const TextPrimary = styled.p(({ theme }) => ({
  ...theme.typography['headline/16'],
  marginBottom: '2px',
  color: 'inherit',

  [`${theme.media.md}`]: {
    ...theme.typography['headline/20'],
    marginBottom: '2px',
  },
}))

export const TextSecondary = styled.p<{ textColor: keyof ITheme['color'] | string }>(
  ({ theme, textColor }) => ({
    ...theme.typography['body/14'],
    color: theme.color[textColor] || textColor,

    [`${theme.media.md}`]: {
      ...theme.typography['body/16'],
    },
  })
)

export const ArrowIcon = styled.span(({ theme }) => ({
  paddingLeft: '8px',
}))

export const SignButton = styled(Button)(({ theme }) => ({
  marginTop: 8,

  [`${theme.media.md}`]: {
    marginTop: '16px',
  },
}))
