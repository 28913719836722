import React, { SyntheticEvent } from 'react'
import { useAppContext } from '@/core'
import { AppContext } from '@/models/AppContext'
import Link from '@/next/link'
import { ClientProductsModel } from '@/api/products/clientProducts'
import { AlienCardsModel } from '@/api/products/alienCards/AlienCardsModel'
import { ProductPlate } from '@/components/ProductPlate'
import Image from '@/next/image'
import InDevelopmentIcon from '../../assets/IndevelopmentIcon.svg'
import { InfoSlot } from '../../components/InfoSlot'

export type ProductItemAlienCardProps = {
  className?: string
  product: AlienCardsModel.AlienCard
  isEdit?: boolean
  isHidden?: boolean
  toggleHidden: (widgetId: ClientProductsModel.CreditCard['settingWidgetId']) => void
  onClick: () => void
  isHideDevelopmentInProgress: boolean
}

const useInfoSlot = (
  onClick: () => void,
  isHideDevelopmentInProgress: boolean
): {
  text: string | JSX.Element
  buttonText?: string
  buttonClick?: React.MouseEventHandler<HTMLButtonElement>
  icon?: React.ReactNode
} | null => {
  if (isHideDevelopmentInProgress) {
    return {
      icon: <Image src={InDevelopmentIcon} />,
      text: 'Этот раздел сайта находится в разработке',
      buttonText: 'Подробнее',
      buttonClick: onClick,
    }
  }
  return null
}

export const ProductItemAlienCard: React.FC<ProductItemAlienCardProps> = (props) => {
  const {
    product,
    isEdit = false,
    isHidden = false,
    toggleHidden,
    onClick,
    isHideDevelopmentInProgress,
  } = props
  const context = useAppContext<AppContext>()
  const infoSlotData = useInfoSlot(onClick, isHideDevelopmentInProgress)

  const onToggleHidden = (e: SyntheticEvent) => {
    e.preventDefault()
    toggleHidden(product.settingWidgetId)
  }

  if (isHideDevelopmentInProgress) {
    return (
      <ProductPlate
        onClick={onClick}
        bankIcon={product.bankLogo}
        description={product.description}
        lastFourCardDigits={product.lastFourCardDigits}
        name={product.bankShortName}
        paymentSystem={product.paymentSystem}
        bgColor={product.bgColor}
        bgColorTheme={product.bgColorTheme}
        isEdit={isEdit}
        isHidden={isHidden}
        toggleHidden={onToggleHidden}
        infoSlot={infoSlotData && <InfoSlot {...infoSlotData} />}
      />
    )
  }

  return (
    <Link key={product.id} href={context.routes.card(product.id)} passHref>
      <ProductPlate
        bankIcon={product.bankLogo}
        description={product.description}
        lastFourCardDigits={product.lastFourCardDigits}
        name={product.bankShortName}
        paymentSystem={product.paymentSystem}
        bgColor={product.bgColor}
        bgColorTheme={product.bgColorTheme}
        isEdit={isEdit}
        isHidden={isHidden}
        toggleHidden={onToggleHidden}
        infoSlot={infoSlotData && <InfoSlot {...infoSlotData} />}
      />
    </Link>
  )
}
