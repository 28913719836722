import { useOfferContentQuery } from '../../../api/products/offerContent/offerContent'
import { offerContentSelector } from '../../../selectors/offerContentSelector'

export const useOfferContent = () => {
  const offerContent = useOfferContentQuery()

  return offerContentSelector({
    offerContent,
  })
}
