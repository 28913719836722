import { CheckIcon } from '@platform-ui/icons/CheckIcon'
import { ApiStatus, useAppContext } from '@/core'
import Link from '@/next/link'
import { pluralize } from '@/utils/pluralize'
import { useClosedProducts } from '@/hooks/useClosedProducts'
import { Root, IconWrapper, Text } from './styled'

interface ClosedProductsButtonProps {
  countClosedProducts: number
  statusProducts: ApiStatus
  statusCreditLoans: ApiStatus
}

export const ClosedProductsButton = () => {
  const context = useAppContext()
  const { countClosedProducts, statusProducts, statusCreditLoans }: ClosedProductsButtonProps =
    useClosedProducts()

  if (
    !!countClosedProducts &&
    statusProducts !== ApiStatus.Pending &&
    statusCreditLoans !== ApiStatus.Pending
  ) {
    return (
      <Root>
        <Link href={context.routes.closed()}>
          <IconWrapper>
            <CheckIcon color="icons/primary" />
          </IconWrapper>

          <Text>
            {countClosedProducts}{' '}
            {pluralize(countClosedProducts, ['закрытый', 'закрытых', 'закрытых'])}{' '}
            {pluralize(countClosedProducts, ['продукт', 'продукта', 'продуктов'])}{' '}
          </Text>
        </Link>
      </Root>
    )
  }

  return null
}
