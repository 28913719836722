import { useQuery, UseQueryOptions } from 'react-query'
import { ApiManager, ApiManagerModel, HttpClient } from '@/core'
import { ApiState } from '@/models/ApiState'
import { OffersModel } from './OffersModel'
import { adapter } from './adapters/adapter'

export const offers: OffersModel.Func = async () => {
  const { path, control } = ApiManager.getState<ApiState, ApiManagerModel.ApiOption>(
    (state) => state.offers.getOffers,
    {} as ApiManagerModel.ApiOption
  )

  const requestData: OffersModel.RequestData = {
    displayScreensIds: [],
    screenGroupsIds: [],
  }

  const response = await HttpClient.post<
    OffersModel.ResponseData,
    void,
    OffersModel.RequestData,
    void,
    OffersModel.ErrorResponse
  >({
    url: path,
    data: requestData,
    responseAdapter: adapter,
    control,
  })

  return response
}

function offersKey() {
  return '/api/offers/getOffers'
}

export function useOffersQuery<T extends OffersModel.ResponseData = OffersModel.ResponseData>(
  options: Omit<
    UseQueryOptions<OffersModel.ResponseData, OffersModel.ErrorResponse, T>,
    'queryKey' | 'queryFn'
  > = {}
) {
  const key = offersKey()
  const result = useQuery(key, (state) => offers().then((res) => res.response), {
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    ...options,
  })

  return result
}
