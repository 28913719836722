import React, { SyntheticEvent, useState } from 'react'
import { AboutSecurityIcon } from '@platform-ui/icons/AboutSecurityIcon'
import { LockIcon } from '@platform-ui/icons/LockIcon'
import { FreezeIcon } from '@platform-ui/icons/FreezeIcon'
import { StopIcon } from '@platform-ui/icons/StopIcon'
import { useAppContext } from '@/core'
import { AppContext } from '@/models/AppContext'
import { ProductsModel } from '@/api/products/products'
import { ProductPlate } from '@/components/ProductPlate'
import { InfoSlot } from '../../components/InfoSlot'
import { TimeIcon } from '@platform-ui/icons/TimeIcon'
import { observer } from 'mobx-react-lite'
import { ProductItemWrapper } from '../../components/ProductItemWrapper'
import { ProductInDevelopmentDialog } from '../../components/ProductInDevelopmentDialog'
import { BaseModal } from '@platform-ui/components'
import { DialogFreezeCard } from '@/features/dialog-freeze-card'

const useInfoSlot = (
  product: ProductsModel.DebitCard,
  onClick: () => void,
  handleOpenFreezeCard: () => void
): {
  text: string
  buttonText?: string
  buttonClick?: React.MouseEventHandler<HTMLButtonElement>
  icon?: React.ReactNode
} | null => {
  const { cardStatusDisplayed } = product

  if (cardStatusDisplayed === ProductsModel.CardStatusDisplayed.New) {
    return {
      text: 'Карта выпускается',
      icon: <TimeIcon color="icons/main" size="s8" />,
    }
  }
  if (cardStatusDisplayed === ProductsModel.CardStatusDisplayed.NotActive) {
    return {
      text: 'Карта не активирована',
      buttonText: 'Активировать карту',
      buttonClick: onClick,
      icon: <AboutSecurityIcon color="icons/main" size="s8" />,
    }
  }
  if (cardStatusDisplayed === ProductsModel.CardStatusDisplayed.Blocked) {
    return {
      text: 'Вы заблокировали карту',
      buttonText: 'Разблокировать',
      buttonClick: handleOpenFreezeCard,
      icon: <FreezeIcon color="icons/main" size="s8" />,
    }
  }
  if (cardStatusDisplayed === ProductsModel.CardStatusDisplayed.BlockedByBank) {
    return {
      text: 'Карта заблокирована банком',
      buttonText: 'Чат поддержки',
      buttonClick: onClick,
      icon: <LockIcon color="icons/main" size="s8" />,
    }
  }
  if (cardStatusDisplayed === ProductsModel.CardStatusDisplayed.Closed) {
    return {
      text: 'Карта закрыта',
      buttonText: 'Скрыть',
      buttonClick: onClick,
      icon: <StopIcon color="icons/main" size="s8" />,
    }
  }
  return null
}

export type ProductItemDebitCardProps = {
  className?: string
  product: ProductsModel.DebitCard
  isEdit?: boolean
  isHidden?: boolean
  toggleHidden: (widgetId: ProductsModel.DebitCard['settingWidgetId']) => void
}

export const ProductItemDebitCard: React.FC<ProductItemDebitCardProps> = observer((props) => {
  const { product, isEdit = false, isHidden = false, toggleHidden } = props
  const context = useAppContext<AppContext>()
  const [isOpenInDevelopmentModal, setIsOpenInDevelopmentModal] = useState(false)
  const [isOpenDialogFreezeCard, setIsOpenDialogFreezeCard] = useState(false)

  const isHideDevelopmentInProgress =
    product.cardStatusDisplayed !== ProductsModel.CardStatusDisplayed.Active &&
    product.cardStatusDisplayed !== ProductsModel.CardStatusDisplayed.Blocked
  const onClick = () => {
    if (isHideDevelopmentInProgress) {
      setIsOpenInDevelopmentModal(true)
    }
  }

  const handleOpenFreezeCard = () => {
    if (!isOpenDialogFreezeCard) {
      setIsOpenDialogFreezeCard(true)
    }
  }

  const infoSlotData = useInfoSlot(props.product, onClick, handleOpenFreezeCard)

  const onToggleHidden = (e: SyntheticEvent) => {
    e.preventDefault()
    toggleHidden(product.settingWidgetId)
  }
  const productInContext = context.productsInContext.arr.find((item) => item?.id === product.id)

  return (
    <>
      <ProductItemWrapper
        href={context.routes.card(product.id)}
        isHideDevelopmentInProgress={isHideDevelopmentInProgress}
      >
        <ProductPlate
          onClick={onClick}
          bgColor={product.bgColor}
          bgColorTheme={product.bgColorTheme}
          name={product.productName}
          description={product.description}
          paymentSystem={product.paymentSystem}
          lastFourCardDigits={product.lastFourCardDigits}
          amount={productInContext?.availableBalance || product.availableBalance}
          currencyType={product.currencyType}
          infoSlot={infoSlotData && <InfoSlot {...infoSlotData} />}
          isEdit={isEdit}
          isHidden={isHidden}
          toggleHidden={onToggleHidden}
        />
      </ProductItemWrapper>
      <ProductInDevelopmentDialog
        isOpen={isOpenInDevelopmentModal}
        onClose={() => setIsOpenInDevelopmentModal(false)}
      />
      <BaseModal
        title={'Разблокировать карту'}
        onClose={() => setIsOpenDialogFreezeCard(!isOpenDialogFreezeCard)}
        isOpen={isOpenDialogFreezeCard}
      >
        <DialogFreezeCard
          cardStatus={product.cardStatusDisplayed}
          onClose={() => setIsOpenDialogFreezeCard(!isOpenDialogFreezeCard)}
          isFromModal={false}
          cardNumber={product.maskCardNumber}
          cardMBR={product.mbr}
          cardType={'debitCard'}
        />
      </BaseModal>
    </>
  )
})
