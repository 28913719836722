import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useAppContext } from '@/core'
import { AppContext } from '@/models/AppContext'
import { ButtonsForHiddenProducts } from './containers/ButtonsForHiddenProducts'
import { ProductListCredits } from './containers/ProductListCredits'
import { ProductListStockpiling } from './containers/ProductListStockpiling'
import { ProductListWallet } from './containers/ProductListWallet'
import { useCustomScrollRestoration } from '@/hooks/useCustomScrollRestoration'
import { ProductListMFO } from './containers/ProductListMFO'
import { ProductListOffer } from './containers/ProductListOffer/ProductListOffer'
import { ClosedProductsButton } from './containers/ClosedProductsButton'

export type ProductListsProps = Readonly<{
  className?: string
}>

export const ProductLists = observer<ProductListsProps>(({ className }) => {
  const context = useAppContext<AppContext>()
  useCustomScrollRestoration()

  return (
    <>
      <ProductListWallet />
      <ProductListCredits />
      <ProductListMFO />
      <ProductListStockpiling />
      <ProductListOffer />

      <ClosedProductsButton />

      {!context.config.features.hideDevelopmentInProgress && <ButtonsForHiddenProducts />}
    </>
  )
})
