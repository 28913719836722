import { ResponseType } from '@/models/ResponseType'
import { RejectedWithSystemErrorResponse } from '@/models/RejectedWithSystemErrorResponse'
import { HttpClient } from '@/core'
import { AdapterModel } from './AdapterModel'
import { OfferContentModel } from '../OffersModel'

export const adapter: AdapterModel.Func = (data) => {
  const systemErrorData = data as AdapterModel.ResponseDataSystemError

  if (systemErrorData?.error && systemErrorData?.status) {
    const exception: RejectedWithSystemErrorResponse = {
      type: ResponseType.RejectedWithSystemError,
      systemErrors: [
        {
          message: 'Произошла ошибка на стороне сервера',
          type: 'INTERNAL_SERVER_ERROR',
        },
      ],
    }
    throw HttpClient.createError(systemErrorData.status, exception)
  }

  try {
    const successData = data as AdapterModel.ResponseDataSuccess

    return {
      type: ResponseType.ResolveWithData,
      products: successData.offersContents.map<OfferContentModel.Content>((offer) => {
        return {
          ...offer,
        }
      }),
    }
  } catch (error) {
    const exception: RejectedWithSystemErrorResponse = {
      type: ResponseType.RejectedWithSystemError,
      systemErrors: [{ message: 'Ошибка при обработке полученных данных', type: 'ADAPTER_ERROR' }],
    }

    throw HttpClient.createError(500, exception, error)
  }
}
