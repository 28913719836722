import styled from '@emotion/styled'

export const BlockDigitalWalletsTitle = styled.div<{}>(({}) => ({
  fontSize: '20px',
  fontWeight: '700',
  marginBottom: '8px',
  marginTop: '32px',
}))

export const BlockDigitalWalletsContentContainer = styled.div<{}>(({ theme }) => ({
  display: 'flex',
  height: '80px',
}))

export const BlockDigitalWalletsContentItem = styled.div<{}>(({ theme }) => ({
  width: '60px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}))

export const BlockDigitalWalletsContentIcon = styled.div<{ image: string }>(({ theme, image }) => ({
  width: '48px',
  height: '48px',
  background: `url(${image}) no-repeat`,
  backgroundSize: 'contain',
  marginBottom: '10px',
}))
