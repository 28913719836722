import React from 'react'
import { OfferContentModel } from '../../../../api/products/offerContent/OffersModel'
import {
  BlockDigitalWalletsContentContainer,
  BlockDigitalWalletsContentIcon,
  BlockDigitalWalletsContentItem,
  BlockDigitalWalletsTitle,
} from './styled'

export const BlockDigitalWallets = ({ blockContent }) => {
  return (
    <>
      <BlockDigitalWalletsTitle>{blockContent.title}</BlockDigitalWalletsTitle>
      <BlockDigitalWalletsContentContainer>
        {blockContent.content.map((content: OfferContentModel.BlockContent) => {
          return (
            <>
              <BlockDigitalWalletsContentItem>
                <BlockDigitalWalletsContentIcon image={content.icon} />
              </BlockDigitalWalletsContentItem>
            </>
          )
        })}
      </BlockDigitalWalletsContentContainer>
    </>
  )
}
