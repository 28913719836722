import { MutateOptions, useMutation } from 'react-query'
import { ApiManager, ApiManagerModel, HttpClient } from '@/core'
import { ApiState } from '@/models/ApiState'
import { adapter } from './adapters/adapter'
import { DcrUrlModel } from './DcrUrlModel'

export const dcrUrl: DcrUrlModel.Func = async (props) => {
  const { path, control } = ApiManager.getState<ApiState, ApiManagerModel.ApiOption>(
    (state) => state.approvalContracts.debitCardRegistry,
    {} as ApiManagerModel.ApiOption
  )

  const response = await HttpClient.post<
    DcrUrlModel.ResponseData,
    void,
    DcrUrlModel.Requestdata,
    DcrUrlModel.RequestHeaders,
    DcrUrlModel.ErrorResponse
  >({
    url: path,
    headers: null,
    responseAdapter: adapter,
    data: {
      product: props.product,
    },
    control,
  })

  return response
}

export function useDcrUrlMutation(
  options?: MutateOptions<DcrUrlModel.Response, DcrUrlModel.ErrorResponse, DcrUrlModel.Props>
) {
  return useMutation(dcrUrl, options)
}
