import { useOffersQuery } from '../../../api/products/offers/offers'
import { offerProductsSelector } from '../../../selectors/offerProductsSelector'

export const useOfferProducts = () => {
  const offers = useOffersQuery()

  return offerProductsSelector({
    offers,
  })
}
