import styled from '@emotion/styled'
import { ChevronRightIcon, PlusIcon } from '@platform-ui/icons'

export const BlockNumberedListTitle = styled.div<{}>(({}) => ({
  fontSize: '20px',
  fontWeight: '700',
  marginBottom: '8px',
}))

export const BlockNumberedListContentIcon = styled.div<{ image: string }>(({ theme, image }) => ({
  width: '48px',
  height: '48px',
  background: `url(${image}) no-repeat`,
  backgroundSize: 'contain',
}))
