import React from 'react'
import {
  DialogHeaderWrapper,
  CloseIconButton,
  ContentBlocks,
  OfferContentBlocksContainer,
} from './styled'
import { ClearIcon } from '@platform-ui/icons'
import { BlockHtml } from '../BlockHtml/BlockHtml'
import { BlockLinkText } from '../BlockLinkText/BlockLinkText'
import { BlockUnnumberedList } from '../BlockUnnumberedList'
import { BlockNumberedList } from '../BlockNumberedList/BlockNumberedList'
import { BlockPartners } from '../BlockPartners/BlockPartners'
import { BlockDigitalWallets } from '../BlockDigitalWallets/BlockDigitalWallets'
import { BlockIconList } from '../BlockIconList/BlockIconList'
import {
  BLOCK_TYPE_DIGITAL_WALLET,
  BLOCK_TYPE_HTML_TEXT,
  BLOCK_TYPE_ICON_LIST,
  BLOCK_TYPE_LINK_TEXT,
  BLOCK_TYPE_NUMBERED_LIST,
  BLOCK_TYPE_PARTNERS,
  BLOCK_TYPE_UNNUMBERED_LIST,
} from '../../constants'
import { OfferContentModel } from '../../../../api/products/offerContent/OffersModel'

export type OfferContentBlockProps = {
  productById: OfferContentModel.Content
  isMobile: boolean
  onClose?: () => void
}

export const OfferContentBlock = ({ productById, isMobile, onClose }: OfferContentBlockProps) => {
  return (
    <OfferContentBlocksContainer>
      {!isMobile && (
        <DialogHeaderWrapper>
          <CloseIconButton onClick={onClose}>
            <ClearIcon color="text/main" />
          </CloseIconButton>
        </DialogHeaderWrapper>
      )}

      <ContentBlocks>
        {productById.blocks.map((block) => {
          if (block.type == BLOCK_TYPE_HTML_TEXT) {
            return <BlockHtml blockContent={block} />
          }
          if (block.type == BLOCK_TYPE_LINK_TEXT) {
            return <BlockLinkText blockContent={block} />
          }
          if (block.type == BLOCK_TYPE_UNNUMBERED_LIST) {
            return <BlockUnnumberedList blockContent={block} />
          }
          if (block.type == BLOCK_TYPE_NUMBERED_LIST) {
            return <BlockNumberedList blockContent={block} />
          }
          if (block.type == BLOCK_TYPE_PARTNERS) {
            return <BlockPartners blockContent={block} />
          }
          if (block.type == BLOCK_TYPE_DIGITAL_WALLET) {
            return <BlockDigitalWallets blockContent={block} />
          }
          if (block.type == BLOCK_TYPE_ICON_LIST) {
            return <BlockIconList blockContent={block} />
          }
        })}
      </ContentBlocks>
    </OfferContentBlocksContainer>
  )
}
