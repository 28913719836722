import React, { SyntheticEvent } from 'react'
import { useAppContext } from '@/core'
import { AppContext } from '@/models/AppContext'
import Link from '@/next/link'
import { InfoSlot } from '../../components/InfoSlot'
import { ProductPlate } from '@/components/ProductPlate'
import { LoanIcon } from '@platform-ui/icons/LoanIcon'
import { ClientProductsModel } from '@/api/products/clientProducts'
import { pluralize } from '@/utils/pluralize'
import { IconWrapper } from '@/components/IconWrapper'
import { CurrencyType } from '@/models/CurrencyType'
import { currency } from '@/utils/currency'
import { observer } from 'mobx-react-lite'
import { ExclamationFiilledIcon } from '@platform-ui/icons'
import { SumToPay, Wrapper, TextStyled } from './styled'

const useInfoSlot = (
  product: ClientProductsModel.CreditLoan
): {
  text: string | JSX.Element
  buttonText?: string
  buttonClick?: React.MouseEventHandler<HTMLButtonElement>
  icon?: React.ReactNode
} | null => {
  const { status } = product

  const currencyComponent = (sum: number, type: CurrencyType) => (
    <SumToPay>{currency(sum, type).format()}</SumToPay>
  )

  if (status === ClientProductsModel.CreditLoanGuiStatus.SoldOut) {
    return {
      text: (
        <Wrapper>
          <ExclamationFiilledIcon color="icons/main" size="s10" />
          <TextStyled>Кредит перешёл на обслуживание в Банк-партнёр</TextStyled>
        </Wrapper>
      ),
    }
  }

  if (
    status === ClientProductsModel.CreditLoanGuiStatus.PaymentStronglyRecommended ||
    status === ClientProductsModel.CreditLoanGuiStatus.BothPaymentPaymentStronglyRecommended
  ) {
    const sumToPay = currencyComponent(product.sumToPay, product.currencyType)
    const pluralizedDay = pluralize(product.daysLeft, ['день', 'дня', 'дней'])

    return {
      text: (
        <>
          Платёж через {product.daysLeft} {pluralizedDay} на сумму {sumToPay}
        </>
      ),
      buttonText: 'Внести платёж',
      buttonClick: () => console.log('Внести платеж'),
    }
  }

  if (
    status === ClientProductsModel.CreditLoanGuiStatus.PaymentDay ||
    status === ClientProductsModel.CreditLoanGuiStatus.BothPaymentPaymentDay
  ) {
    const sumToPay = currency(product.sumToPay, product.currencyType).format()

    return {
      text: <>Сегодня день платежа на сумму {sumToPay}</>,
    }
  }

  if (status === ClientProductsModel.CreditLoanGuiStatus.OverduePayment) {
    const sumToPay = currencyComponent(product.sumToPay, product.currencyType)

    return {
      text: <>Платеж на сумму {sumToPay} просрочен</>,
    }
  }

  return null
}

export type ProductItemCreditLoanProps = {
  className?: string
  product: ClientProductsModel.CreditLoan
  isEdit?: boolean
  isHidden?: boolean
  toggleHidden: (widgetId: ClientProductsModel.CreditLoan['settingWidgetId']) => void
}

export const ProductItemCreditLoan: React.FC<ProductItemCreditLoanProps> = observer((props) => {
  const { product, isEdit = false, isHidden = false, toggleHidden } = props
  const context = useAppContext<AppContext>()
  const infoSlotData = useInfoSlot(props.product)

  const onToggleHidden = (e: SyntheticEvent) => {
    e.preventDefault()
    toggleHidden(product.settingWidgetId)
  }
  const productInContext = context.productsInContext.arr.find((item) => item?.id === product.id)

  return (
    <Link href={context.routes.credit(product.id)} passHref>
      <ProductPlate
        name={product.productName}
        description={product.description}
        icon={
          <IconWrapper backgroundColor="products/loanNew" textColor="text/tertiary">
            <LoanIcon color="icons/shape" size="s12" />
          </IconWrapper>
        }
        amount={productInContext?.loanBalance || product.loanBalance}
        currencyType={product.currencyType}
        infoSlot={infoSlotData && <InfoSlot {...infoSlotData} />}
        isEdit={isEdit}
        isHidden={isHidden}
        toggleHidden={onToggleHidden}
        bgColor={product.bgColor}
        bgColorTheme={product.bgColorTheme}
      />
    </Link>
  )
})
