import React from 'react'
import { observer } from 'mobx-react-lite'
import { OffersModel } from '@/api/products/offers/OffersModel'
import { argbHexToRgba } from '@/utils/colorHelper'
import { MarketplaceOfferPlate } from '../../components/MarketplaceOfferPlate'

export type MarketplaceItemOfferProps = {
  product: OffersModel.MarketplaceOffer
}

export const MarketplaceItemOffer: React.FC<MarketplaceItemOfferProps> = observer((props) => {
  const { product } = props

  return (
    <MarketplaceOfferPlate
      templateId={product.templateId}
      title={product.title}
      subtitle={product.subtitle}
      description={product.description}
      backgroundColor={argbHexToRgba(product.backgroundColor)}
      secondBackgroundColor={argbHexToRgba(product.secondBackgroundColor)}
      imageUrl={product.imageWeb}
      icon={product.icon}
      textColor={argbHexToRgba(product.textColor)}
      url={product.url}
    />
  )
})
