import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import styled from '@emotion/styled'
import DefaultPage from '@/layouts/DefaultPage'
import { ProductLists } from '@/features/product-lists'
import { AppPage } from '@/models/AppPage'
import { PartnersModal } from '@/features/partners-modal'
import { useAppContext } from '@/core'
import { AppContext } from '@/models/AppContext'

const DefaultPageCustom = styled(DefaultPage)(() => {
  return {
    paddingTop: '40px',
    paddingBottom: '40px',
  }
})

const Page: AppPage = observer(() => {
  const context = useAppContext<AppContext>()
  useEffect(() => {
    if (context.popups.screens?.main.length > 0) {
      context.eventBus.popupModal.open.emit({
        popup: context.popups.screens.main[0],
      })
    }
  }, [context])
  return (
    <DefaultPageCustom>
      <ProductLists />
      <PartnersModal />
    </DefaultPageCustom>
  )
})
export default Page
