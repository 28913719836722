import { GetCreditLinesMFOModel } from '@/api/products/mfo/getCreditLinesMFO'

const STATUS_PRIORITY_MAP = new Map<GetCreditLinesMFOModel.StatusCode, number>([
  [GetCreditLinesMFOModel.StatusCode.Demanded, 1],
  [GetCreditLinesMFOModel.StatusCode.Active, 2],
  [GetCreditLinesMFOModel.StatusCode.Signed, 3],
  [GetCreditLinesMFOModel.StatusCode.Closed, 4],
])

const LOWEST_PRIOTITY = 5

export const sortMFOCreditLinesByStatus = (contracts: GetCreditLinesMFOModel.CreditLine[]) => {
  return contracts.sort(
    (a, b) =>
      (STATUS_PRIORITY_MAP.get(a.status) ?? LOWEST_PRIOTITY) -
      (STATUS_PRIORITY_MAP.get(b.status) ?? LOWEST_PRIOTITY)
  )
}
