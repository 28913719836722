import styled from '@emotion/styled'

export const OfferContentContainer = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'auto',
  marginBottom: '88px',

  [`${theme.media.md}`]: {
    marginBottom: '0px',
    height: '576px',
    flexDirection: 'row',
    overflowY: 'hidden',
  },
}))

export const ContentBlockListItemText = styled.div<{}>(({}) => ({
  fontSize: '16px',
  fontWeight: '500',
  display: 'flex',
  alignItems: 'center',
  color: 'black',
  marginLeft: '16px',
}))

export const ContentBlockListItemContainer = styled.div<{}>(({}) => ({
  display: 'flex',
  marginBottom: '12px',
}))

export const DialogButton = styled.div(({ theme }) => ({
  position: 'absolute',
  width: '100%',
  bottom: 0,
  padding: '16px 16px 16px 16px',
  background: 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 23.19%)',
  borderRadius: 24,
  zIndex: 1,

  [`${theme.media.md}`]: {
    padding: '24px 32px 24px 32px',
    position: 'unset',
    bottom: 18,
  },
}))

export const DialogHeaderWrapper = styled.div(({ theme }) => ({
  height: 64,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',

  [`${theme.media.md}`]: {
    marginTop: 16,
    position: 'relative',
  },
}))
