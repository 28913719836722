import styled from '@emotion/styled'

export const OfferProcessContainer = styled.div<{}>(({ theme }) => ({
  background: 'white',
  height: '100%',
  width: '100%',
  borderRadius: '12px',
  [`${theme.media.md}`]: {
    height: '690px',
  },
}))

export const DialogHeaderWrapper = styled.div(({ theme }) => ({
  position: 'relative',
  height: 64,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',

  [`${theme.media.md}`]: {
    marginTop: 16,
  },
}))
