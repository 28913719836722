import { ApiManager, ApiManagerModel, HttpClient } from '@/core'
import { SaveResponseModel } from './saveResponseModel'
import { ApiState } from '@/models/ApiState'
import { useQuery, UseQueryOptions } from 'react-query'
import { adapter } from './adapters/adapter'

export const saveResponse: SaveResponseModel.Func = async (props) => {
  const { path, control } = ApiManager.getState<ApiState, ApiManagerModel.ApiOption>(
    (state) => state.approvalContracts.saveResponse,
    {} as ApiManagerModel.ApiOption
  )

  const response = await HttpClient.post<
    SaveResponseModel.ResponseData,
    void,
    SaveResponseModel.RequestData,
    null,
    SaveResponseModel.ErrorResponse
  >({
    url: path,
    data: {
      Responses: [props],
    },
    headers: null,
    responseAdapter: adapter,
    control,
  })

  return response
}

function saveResponseKey(props: SaveResponseModel.ClientOfferResponse) {
  return [
    '/api/approvalContracts/saveResponse',
    props.OfferId,
    props.ClickId,
    props.SessionId,
    props.Type,
  ]
}

export function useSaveResponseQuery<T = SaveResponseModel.ResponseData>(
  props: SaveResponseModel.Props,
  options: Omit<
    UseQueryOptions<SaveResponseModel.ResponseData, SaveResponseModel.ErrorResponse, T>,
    'queryKey' | 'queryFn'
  > = {}
) {
  const key = saveResponseKey(props)
  const result = useQuery(key, async () => saveResponse({ ...props }).then((res) => res.data), {
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    ...options,
  })

  return result
}
