import styled from '@emotion/styled'
import { ITheme } from '@platform-ui/core'

export const OfferContentAdvantages = styled.div<{
  firstBackgroundColor: string
  secondBackgroundColor: string
}>(({ theme, firstBackgroundColor, secondBackgroundColor }) => ({
  background: `linear-gradient(338.53deg, ${secondBackgroundColor} 0%, ${firstBackgroundColor} 100%);`,
  paddingLeft: '24px',
  paddingRight: '24px',
  paddingBottom: '44px',
  marginBottom: '44px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  borderRadius: '0px 0px 16px 16px',

  [`${theme.media.md}`]: {
    width: '320px',
    height: '100%',
    paddingBottom: '0px',
    borderRadius: '24px 0px 0px 0px',
  },
}))

export const AdvantagesTitle = styled.div<{ textColor: keyof ITheme['color'] | string }>(
  ({ theme, textColor }) => ({
    fontSize: '32px',
    fontWeight: '700',
    marginBottom: '2px',
    lineHeight: '38px',
    color: textColor || 'black',
    textAlign: 'center',

    [`${theme.media.md}`]: {
      marginBottom: '2px',
      paddingTop: '32px',
    },
  })
)

export const AdvantagesSubtitle = styled.div<{ textColor: keyof ITheme['color'] | string }>(
  ({ theme, textColor }) => ({
    fontSize: '16px',
    fontWeight: '500',
    marginBottom: '2px',
    lineHeight: '24px',
    color: textColor || 'black',
    textAlign: 'center',
  })
)

export const AdvantagesImage = styled.div<{ image: string }>(({ theme, image }) => ({
  width: '215px',
  height: '134px',
  background: `url(${image}) no-repeat`,
  backgroundSize: 'contain',
  marginBottom: '32px',
}))

export const AdvantagesItemContainer = styled.div<{}>(({}) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '12px',
}))

export const AdvantagesBlockContentIcon = styled.div<{ image: string }>(({ theme, image }) => ({
  width: '48px',
  height: '48px',
  background: `url(${image}) no-repeat`,
  backgroundSize: 'contain',
}))

export const AdvantagesBlockContentText = styled.div<{ textColor: string }>(
  ({ theme, textColor }) => ({
    width: '208px',
    height: '48px',
    fontSize: '16px',
    fontWeight: '500',
    display: 'flex',
    alignItems: 'center',
    color: textColor || 'white',
    marginLeft: '16px',
  })
)
