import React, { forwardRef, useState } from 'react'
import { Dialog } from '@platform-ui/components/Dialog'
import { OfferContentDetail } from '../../../offer-content'
import { Offer, OfferDescription, OfferSubtitle, OfferTitle } from './styled'
import { useAppContext } from '@/core'
import { AppContext } from '@/models/AppContext'
import { Screen } from '@platform-ui/components/Screen'

type OfferPlateProps = Readonly<{
  templateId: number
  title: string
  subtitle: string
  description: string
  backgroundColor: string
  textColor: string
  icon: string
  imageUrl: string
  onClick?: () => void
}>

export const OfferPlate = forwardRef<HTMLAnchorElement, OfferPlateProps>((props) => {
  const onClick = () => {
    setIsOpenModal(true)
  }

  const onCloseModal = () => {
    setIsOpenModal(false)
  }

  const { config } = useAppContext<AppContext>()
  const isMobile = config.device.isMobile

  const [isOpenModal, setIsOpenModal] = useState(false)

  const { backgroundColor, imageUrl, title, subtitle, description, textColor, icon, templateId } =
    props

  return (
    <>
      <Offer backgroundColor={backgroundColor} imageUrl={imageUrl} icon={icon} onClick={onClick}>
        <OfferTitle textColor={textColor}>{title}</OfferTitle>
        <OfferSubtitle textColor={textColor}>{subtitle}</OfferSubtitle>
        <OfferDescription textColor={textColor}>{description}</OfferDescription>
      </Offer>

      {isMobile ? (
        <Screen isOpen={isOpenModal} onClose={onCloseModal}>
          <OfferContentDetail templateId={templateId} onClose={onCloseModal} isMobile={isMobile} />
        </Screen>
      ) : (
        <Dialog isOpen={isOpenModal} onClose={onCloseModal} smMaxBoxSize={'s100'}>
          <OfferContentDetail templateId={templateId} onClose={onCloseModal} isMobile={isMobile} />
        </Dialog>
      )}
    </>
  )
})
