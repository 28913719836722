import styled from '@emotion/styled'

export const BlockUnnumberedListTitle = styled.div<{}>(({}) => ({
  fontSize: '20px',
  fontWeight: '700',
  marginBottom: '8px',
}))

export const BlockUnnumberedListContentIcon = styled.div<{}>(({ theme }) => ({
  width: '8px',
  height: '8px',
  backgroundColor: 'black',
  borderRadius: '8px',
  paddingRight: '8px',
  marginTop: '4px',
}))
