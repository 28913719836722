import React from 'react'
import { OfferContentModel } from '../../../../api/products/offerContent/OffersModel'
import { BlockIconListContentIcon, BlockIconListTitle } from './styled'
import { ContentBlockListItemContainer, ContentBlockListItemText } from '../../styled'

export const BlockIconList = ({ blockContent }) => {
  return (
    <>
      <BlockIconListTitle>{blockContent.title}</BlockIconListTitle>
      {blockContent.content.map((content: OfferContentModel.BlockContent) => {
        return (
          <>
            <ContentBlockListItemContainer>
              <BlockIconListContentIcon image={content.icon} />
              <ContentBlockListItemText>{content.text}</ContentBlockListItemText>
            </ContentBlockListItemContainer>
          </>
        )
      })}
    </>
  )
}
