import React from 'react'
import Link, { LinkProps } from '@/next//link'

export interface ProductItemWrapperProps {
  href: LinkProps['href']
  children: React.ReactNode
  isHideDevelopmentInProgress: boolean
}

export const ProductItemWrapper = (props: ProductItemWrapperProps) => {
  const { children, isHideDevelopmentInProgress, href } = props

  if (isHideDevelopmentInProgress) {
    return <>{children}</>
  }

  return (
    <Link href={href} passHref>
      {children}
    </Link>
  )
}
