import React, { useEffect, useState } from 'react'
import { ProductList } from '../../components/ProductList'
import { ProductShimmer } from '../../components/ProductShimmer'
import { ProductsModel } from '@/api/products/products'
import { ProductItemDeposit } from '../ProductItemDeposit'
import { ProductItemAccount } from '../ProductItemAccount'
import { ApiStatus } from '@/core'
import { ProductError } from '../../components/ProductError'
import { observer } from 'mobx-react-lite'
import { useStockpilingProducts } from '../../hooks/useStockpilingProducts'
import { useWidetsSettingsStore } from '../../widgetsSettingsStore/WidgetsSettingsStore'
import { ProductInDevelopmentDialog } from '@/features/product-lists/components/ProductInDevelopmentDialog'

export type ProductListStockpilingProps = {
  className?: string
}

export const ProductListStockpiling = observer(
  (props: ProductListStockpilingProps): JSX.Element => {
    const { className } = props
    const { products, visibleProductsIds, status, error, retry, hasAnyResult } =
      useStockpilingProducts()

    const widgetsSettings = useWidetsSettingsStore()
    const widgetsSettingsMap = widgetsSettings.getSettingsMap
    const isEdit = widgetsSettings.isEdit

    const [isLoading, setIsLoading] = useState(!hasAnyResult)
    const [isOpenModal, setIsOpenModal] = useState(false)

    useEffect(() => {
      /**
       * Требуется показывать заголовок если данные ранее уже были получены
       */
      if (hasAnyResult) {
        setIsLoading(false)
      }
    }, [hasAnyResult])

    if (
      status === ApiStatus.Idle ||
      (error && error.type === 'IS_EMPTY_PRODUCTS') ||
      (status === ApiStatus.Fulfilled && visibleProductsIds.length === 0)
    ) {
      return null
    }

    return (
      <ProductList title="Накопления" isLoading={isLoading} className={className}>
        {visibleProductsIds.map((productId, i) => {
          const item = products[productId]
          if (item.productType === ProductsModel.ProductType.Deposit) {
            return (
              <ProductItemDeposit
                key={item.id}
                product={item}
                isEdit={isEdit}
                isHidden={widgetsSettingsMap[item.settingWidgetId]?.isHidden}
                toggleHidden={() => widgetsSettings.toggleHidden(item)}
              />
            )
          }
          return (
            <ProductItemAccount
              key={item.id}
              product={item}
              isEdit={isEdit}
              isHidden={widgetsSettingsMap[item.settingWidgetId]?.isHidden}
              toggleHidden={() => widgetsSettings.toggleHidden(item)}
            />
          )
        })}
        {status === ApiStatus.Pending ? (
          <ProductShimmer />
        ) : (
          error && (
            <ProductError
              message="При получении накопительных продуктов произошла ошибка."
              retryClick={retry}
            />
          )
        )}
        <ProductInDevelopmentDialog isOpen={isOpenModal} onClose={() => setIsOpenModal(false)} />
      </ProductList>
    )
  }
)
