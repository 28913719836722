import { ApiManager, ApiManagerModel, HttpClient } from '@/core'
import { CcSignModel } from './ccSignModel'
import { ApiState } from '@/models/ApiState'
import { useAppContext } from '@/core'
import { AppContext } from '@/models/AppContext'
import { useQuery, UseQueryOptions } from 'react-query'
import { adapter } from './adapters/adapter'

export const ccSign: CcSignModel.Func = async (props) => {
  const { path, control } = ApiManager.getState<ApiState, ApiManagerModel.ApiOption>(
    (state) => state.approvalContracts.ccSign,
    {} as ApiManagerModel.ApiOption
  )

  const response = await HttpClient.post<
    CcSignModel.ResponseData,
    void,
    CcSignModel.RequestData,
    CcSignModel.RequestHeaders,
    CcSignModel.ErrorResponse
  >({
    url: path,
    data: { contractNumber: props.contractNumber },
    headers: {
      _ver_: props.apiVersion,
      _os_: '4',
    },
    responseAdapter: adapter,
    control,
  })

  return response
}

function settingsKey() {
  return '/api/approvalContracts/ccSign'
}

export function useCcSignQuery<T = CcSignModel.ResponseData>(
  props: CcSignModel.Props,
  options: Omit<
    UseQueryOptions<CcSignModel.ResponseData, CcSignModel.ErrorResponse, T>,
    'queryKey' | 'queryFn'
  > = {}
) {
  const context = useAppContext<AppContext>()
  const key = settingsKey()
  const result = useQuery(
    key,
    async () => ccSign({ ...props, apiVersion: context.config.apiVersion }).then((res) => res.data),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      ...options,
    }
  )

  return result
}
