import React, { useState } from 'react'
import Image from '@/next//image'
import AddIcon from '../../assets/card-product-icon.svg'
import {
  Content,
  ContentLeft,
  TextPrimary,
  TextSecondary,
  InfoSlotWrapper,
  Root,
  ArrowIcon,
  SignButton,
} from './styled'
import { ITheme } from '@platform-ui/core'
import { useAppContext } from '@/core'
import { AppContext } from '@/models/AppContext'
import { CardSignModal } from '../CardSignModal'
import { Currency, CurrencyProps } from '@/components/Currency'

type ApprovalCardSignProps = Readonly<{
  className?: string
  name: string
  cardType: 'credit' | 'debit'
  info: string
  description: string | React.ReactNode
  ariaLabel?: string
  contractNumber: string
  amount?: CurrencyProps['amount']
  product?: number
  bgColor: string
  bgColorTheme: 'light' | 'dark'
}>

export const ApprovalCardSign = (props: ApprovalCardSignProps) => {
  const {
    className,
    name,
    info,
    description,
    bgColor,
    bgColorTheme,
    cardType,
    contractNumber,
    amount = 0,
    product = 0,
    ...otherProps
  } = props
  const [isOpenSign, setIsOpenSign] = useState<boolean>(false)

  const descriptionTextColor: keyof ITheme['color'] =
    bgColorTheme === 'dark' ? 'text/tertiaryTransparent' : 'text/mainTransparent'

  const textColor: keyof ITheme['color'] = bgColorTheme === 'dark' ? 'text/tertiary' : 'text/main'

  const borderColor: keyof ITheme['color'] =
    bgColorTheme === 'dark' ? 'surface/lightLine' : 'surface/line'
  const { config } = useAppContext<AppContext>()
  const isMobile = config.device.isMobile

  const onClose = (): void => {
    setIsOpenSign(false)
  }

  return (
    <>
      <Root className={className} bgColor={bgColor} textColor={textColor} {...otherProps}>
        <Content>
          <Image src={AddIcon} width={40} height={40} alt="Plus" />
          <ContentLeft>
            <TextPrimary>{name}</TextPrimary>
            <TextSecondary textColor={descriptionTextColor}>{description}</TextSecondary>
          </ContentLeft>
          {cardType === 'credit' && (
            <TextPrimary>
              <Currency amount={amount} type={'RUB'} />
            </TextPrimary>
          )}
        </Content>
        <InfoSlotWrapper textColor={descriptionTextColor} borderColor={borderColor}>
          <div>{info}</div>
          <SignButton
            view={'secondary'}
            size="m"
            isFullWidth={isMobile}
            onClick={() => setIsOpenSign(true)}
            rightIcon={<ArrowIcon>{'>'}</ArrowIcon>}
            iconOffsetSm="space-between"
          >
            Получить
          </SignButton>
        </InfoSlotWrapper>
      </Root>
      <CardSignModal
        cardType={cardType}
        product={product}
        contractNumber={contractNumber}
        isOpen={isOpenSign}
        onClose={onClose}
      />
    </>
  )
}
