import React, { SyntheticEvent } from 'react'
import { useAppContext } from '@/core'
import { AppContext } from '@/models/AppContext'
import Link, { LinkProps } from '@/next/link'
import { ProductPlate } from '@/components/ProductPlate'
import { ProductsModel } from '@/api/products/products'
import { SavingAccountIcon } from '@platform-ui/icons/SavingAccountIcon'
import { IconWrapper } from '@/components/IconWrapper'

export type ProductItemAccountProps = {
  className?: string
  product: ProductsModel.Account
  isEdit?: boolean
  isHidden?: boolean
  toggleHidden: (widgetId: ProductsModel.Account['settingWidgetId']) => void
}

export interface ProductItemWrapperProps {
  href: LinkProps['href']
  children: React.ReactNode
}

export const ProductItemWrapper = (props: ProductItemWrapperProps) => {
  const { children, href } = props

  return (
    <Link href={href} passHref>
      {children}
    </Link>
  )
}

export const ProductItemAccount: React.FC<ProductItemAccountProps> = (props) => {
  const { product, isEdit = false, isHidden = false, toggleHidden } = props
  const context = useAppContext<AppContext>()

  const onToggleHidden = (e: SyntheticEvent) => {
    e.preventDefault()
    toggleHidden(product.settingWidgetId)
  }

  const productInContext = context.productsInContext.arr.find((item) => item?.id === product.id)

  return (
    <ProductItemWrapper href={context.routes.account(product.id)}>
      <ProductPlate
        name={product.accountName}
        description={product.description}
        amount={productInContext?.availableBalance || product.runningBalance}
        currencyType={product.currencyType}
        icon={
          <IconWrapper backgroundColor="products/savingsNew" textColor="text/tertiary">
            <SavingAccountIcon color="icons/shape" size="s12" />
          </IconWrapper>
        }
        isEdit={isEdit}
        isHidden={isHidden}
        toggleHidden={onToggleHidden}
        bgColor={product.bgColor}
        bgColorTheme={product.bgColorTheme}
      />
    </ProductItemWrapper>
  )
}
