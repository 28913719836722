import React from 'react'
import { OfferContentModel } from '../../../../api/products/offerContent/OffersModel'
import { BlockUnnumberedListContentIcon, BlockUnnumberedListTitle } from './styled'
import { ContentBlockListItemContainer, ContentBlockListItemText } from '../../styled'

export const BlockUnnumberedList = ({ blockContent }) => {
  return (
    <>
      <BlockUnnumberedListTitle>{blockContent.title}</BlockUnnumberedListTitle>
      {blockContent.content.map((content: OfferContentModel.BlockContent) => {
        return (
          <>
            <ContentBlockListItemContainer>
              <BlockUnnumberedListContentIcon />
              <ContentBlockListItemText>{content.text}</ContentBlockListItemText>
            </ContentBlockListItemContainer>
          </>
        )
      })}
    </>
  )
}
