import React, { useEffect, useState } from 'react'
import { ProductList } from '@/components/ProductList'
import { ProductShimmer } from '../../components/ProductShimmer'
import { ProductItemCreditLoan } from '../ProductItemCreditLoan'
import { ApiStatus } from '@/core'
import { ProductError } from '@/components/ProductError'
import { observer } from 'mobx-react-lite'
import { useCreditProducts } from '../../hooks/useCreditProducts'
import { useWidetsSettingsStore } from '../../widgetsSettingsStore/WidgetsSettingsStore'
import { useGetApprovalContracts } from '../../selectors/approvalContractsSelector'
import { CreditSign } from '../../components/CreditSign'
import { LoanIcon } from '@platform-ui/icons/LoanIcon'
import { IconWrapper } from '@/components/IconWrapper'

export type ProductListCreditsProps = {
  className?: string
}

export const ProductListCredits = observer((props: ProductListCreditsProps): JSX.Element => {
  const { className } = props
  const { products, visibleProductsIds, status, error, retry, hasAnyResult } = useCreditProducts()
  const { approvalCredits, status: contractStatus } = useGetApprovalContracts(3)

  const widgetsSettings = useWidetsSettingsStore()
  const widgetsSettingsMap = widgetsSettings.getSettingsMap
  const isEdit = widgetsSettings.isEdit

  const [isLoading, setIsLoading] = useState(!hasAnyResult)

  useEffect(() => {
    /**
     * Требуется показывать заголовок если данные ранее уже были получены
     */
    if (hasAnyResult) {
      setIsLoading(false)
    }
  }, [hasAnyResult])

  if (
    status === ApiStatus.Idle ||
    (error &&
      error.type === 'IS_EMPTY_PRODUCTS' &&
      !approvalCredits.length &&
      contractStatus === ApiStatus.Fulfilled) ||
    (status === ApiStatus.Fulfilled &&
      visibleProductsIds.length === 0 &&
      !approvalCredits.length &&
      contractStatus === ApiStatus.Fulfilled)
  ) {
    return null
  }

  return (
    <ProductList title="Кредиты" isLoading={isLoading} className={className}>
      {visibleProductsIds.map((productId, i) => {
        const product = products[productId]
        return (
          <ProductItemCreditLoan
            key={product.id}
            product={product}
            isEdit={isEdit}
            isHidden={widgetsSettingsMap[product.settingWidgetId]?.isHidden}
            toggleHidden={() => widgetsSettings.toggleHidden(product)}
          />
        )
      })}
      {!!approvalCredits.length &&
        approvalCredits.map((credit) => (
          <CreditSign
            key={credit.ContractNumber}
            amount={credit.CashDetail.ContractSumWithoutServices}
            name={credit.ProductName}
            currencyType={'RUB'}
            contractNumber={credit.ContractNumber}
            icon={
              <IconWrapper backgroundColor="products/loanNew" textColor="text/tertiary">
                <LoanIcon color="icons/shape" size="s12" />
              </IconWrapper>
            }
          />
        ))}
      {status === ApiStatus.Pending ? (
        <ProductShimmer />
      ) : (
        error &&
        error.type === 'IS_EMPTY_PRODUCTS' && (
          <ProductError message="При получении кредитов произошла ошибка." retryClick={retry} />
        )
      )}
    </ProductList>
  )
})
