import { Body16 } from '@platform-ui/typography'
import styled from '@emotion/styled'

export const SumToPay = styled.span(() => ({
  whiteSpace: 'nowrap',
}))

export const Wrapper = styled.div(({ theme }) => ({
  background: '#fff',
  display: 'flex',
  alignItems: 'center',
  borderRadius: '1.5em',
  width: 'auto',
  height: 'auto',
  padding: '4px 8px',
  gap: 8,
}))

export const TextStyled = styled(Body16)(({ theme }) => ({
  color: theme.color['icons/main'],
}))
