import { ApiManager, ApiManagerModel, HttpClient } from '@/core'
import { DebitCardRegistryModel } from './DebitCardRegistryModel'
import { ApiState } from '@/models/ApiState'
import { useQuery, UseQueryOptions } from 'react-query'
import { adapter } from './adapters/adapter'
import { useAppContext } from '@/core'
import { AppContext } from '@/models/AppContext'

export const debitCardRegistry: DebitCardRegistryModel.Func = async (props) => {
  const { path, control } = ApiManager.getState<ApiState, ApiManagerModel.ApiOption>(
    (state) => state.approvalContracts.debitCardRegistry,
    {} as ApiManagerModel.ApiOption
  )

  const response = await HttpClient.post<
    DebitCardRegistryModel.ResponseData,
    void,
    DebitCardRegistryModel.RequestData,
    DebitCardRegistryModel.RequestHeaders,
    DebitCardRegistryModel.ErrorResponse
  >({
    url: path,
    data: { ContractNumber: props.ContractNumber, Product: props.Product },
    headers: {
      _ver_: props.apiVersion,
      _os_: '4',
    },
    responseAdapter: adapter,
    control,
  })

  return response
}

function settingsKey() {
  return '/api/approvalContracts/debitCardRegistry'
}

export function useDebitCardRegistryQuery<T = DebitCardRegistryModel.ResponseData>(
  props: DebitCardRegistryModel.Props,
  options: Omit<
    UseQueryOptions<DebitCardRegistryModel.ResponseData, DebitCardRegistryModel.ErrorResponse, T>,
    'queryKey' | 'queryFn'
  > = {}
) {
  const context = useAppContext<AppContext>()
  const key = settingsKey()
  const result = useQuery(
    key,
    async () =>
      debitCardRegistry({ ...props, apiVersion: context.config.apiVersion }).then(
        (res) => res.data
      ),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      ...options,
    }
  )

  return result
}
