import React from 'react'
import { BaseModal } from '@platform-ui/components'
import { useDebitCardRegistry } from '../../selectors/debitCardRegistrySelector'
import { useCcSign } from '../../selectors/ccSignSelector'
import { useSaveResponseQuery } from '@/api/approvalContracts/saveResponse'

type ApprovalCardSignProps = Readonly<{
  cardType: 'credit' | 'debit'
  contractNumber: string
  product?: number
  isOpen: boolean
  onClose: () => void
}>

export const CardSignModal = (props: ApprovalCardSignProps) => {
  const { cardType, contractNumber, product, isOpen, onClose } = props

  useSaveResponseQuery({ Type: 1 })

  let url = ''
  const { signUrl: debitCardSignUrl } = useDebitCardRegistry(
    contractNumber,
    product,
    cardType === 'debit'
  )
  const { signUrl: creditSignUrl } = useCcSign(contractNumber, cardType === 'credit')

  if (debitCardSignUrl || creditSignUrl) {
    url = debitCardSignUrl || creditSignUrl
  }

  const closeSignModal = () => {
    url = ''
    onClose()
  }

  return (
    <BaseModal isOpen={isOpen} title="" onClose={closeSignModal}>
      {url && <iframe title="cardSign" src={url} width={'100%'} height={'100%'} />}
    </BaseModal>
  )
}
