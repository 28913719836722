import styled from '@emotion/styled'
import { IconButton } from '@platform-ui/components/IconButton'

export const OfferContentErrorContainer = styled.div<{}>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'space-between',
  [`${theme.media.md}`]: {
    justifyContent: 'unset',
    width: '100%',
    height: '640px',
  },
}))

export const OfferContentErrorInfo = styled.div<{}>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginBottom: '64px',
  [`${theme.media.md}`]: {
    width: '100%',
    height: '472px',
  },
}))

export const OfferContentErrorImage = styled.div<{}>(({ theme }) => ({
  marginBottom: '14px',
  [`${theme.media.md}`]: {
    marginTop: '77px',
  },
}))

export const OfferContentErrorText = styled.div<{}>(({ theme }) => ({
  marginBottom: '12px',
  fontWeight: '700',
  fontSize: '20px',
  lineHeight: '24px',
  textAlign: 'center',
}))

export const OfferContentErrorDesc = styled.div<{}>(({ theme }) => ({
  color: '#797F90',
  fontWeight: '500',
  fontSize: '14px',
  lineHeight: '18px',
  textAlign: 'center',
}))

export const CloseIconButton = styled(IconButton)(() => ({
  position: 'absolute',
  padding: 4,
  right: 16,
}))

export const DialogButton = styled.div(({ theme }) => ({
  width: '100%',
  padding: '16px 16px 16px 16px',
  background: 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 23.19%)',
  borderRadius: 24,

  [`${theme.media.md}`]: {
    position: 'absolute',
    bottom: 0,
    padding: '24px 32px 24px 32px',
  },
}))
