import { useAlienCardsQuery } from '@/api/products/alienCards'
import { useProductsQuery } from '@/api/products/products'
import { useWidgetsSettingsQuery } from '@/api/products/widgetsSettings'
import { useAppContext } from '@/core'
import { AppContext } from '@/models/AppContext'
import { walletProductsWithWigdetSettingsSelector } from '@/selectors/walletProductsWithWigdetSettingsSelector'

export const useWalletProducts = () => {
  const context = useAppContext<AppContext>()

  const products = useProductsQuery()

  const alienCards = useAlienCardsQuery()

  const widgetsSettings = useWidgetsSettingsQuery({
    enabled: !context.config.features.hideDevelopmentInProgress,
  })

  return walletProductsWithWigdetSettingsSelector({
    products,
    alienCards,
    widgetsSettings,
  })
}
