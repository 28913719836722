import React from 'react'
import { BlockHtmlText, BlockHtmlTitle } from './styled'

export const BlockHtml = ({ blockContent }) => {
  return (
    <div key={blockContent.priority} className="blockHtml">
      <BlockHtmlTitle>{blockContent.title}</BlockHtmlTitle>
      <BlockHtmlText dangerouslySetInnerHTML={{ __html: blockContent.text }}></BlockHtmlText>
    </div>
  )
}
