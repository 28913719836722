import React, { SyntheticEvent } from 'react'
import { AboutSecurityIcon } from '@platform-ui/icons/AboutSecurityIcon'
import { LockIcon } from '@platform-ui/icons/LockIcon'
import { FreezeIcon } from '@platform-ui/icons/FreezeIcon'
import { StopIcon } from '@platform-ui/icons/StopIcon'
import { useAppContext } from '@/core'
import { AppContext } from '@/models/AppContext'
import Link from '@/next/link'
import { InfoSlot } from '../../components/InfoSlot'
import { ProductsModel } from '@/api/products/products'
import { pluralize } from '@/utils/pluralize'
import { TimeIcon } from '@platform-ui/icons/TimeIcon'
import { ProductPlate } from '@/components/ProductPlate'
import styled from '@emotion/styled'
import { CurrencyType } from '@/models/CurrencyType'
import { currency } from '@/utils/currency'
import Image from '@/next/image'
import InDevelopmentIcon from '../../assets/IndevelopmentIcon.svg'

const SumToPay = styled.span(() => ({
  whiteSpace: 'nowrap',
}))

const useInfoSlot = (
  product: ProductsModel.CreditCard,
  onClick: () => void,
  isHideDevelopmentInProgress: boolean
): {
  text: string | JSX.Element
  buttonText?: string
  buttonClick?: React.MouseEventHandler<HTMLButtonElement>
  icon?: React.ReactNode
} | null => {
  if (isHideDevelopmentInProgress)
    return {
      icon: <Image src={InDevelopmentIcon} />,
      text: 'Этот раздел сайта находится в разработке',
      buttonText: 'Подробнее',
      buttonClick: onClick,
    }

  const { cardStatusDisplayed } = product

  const currencyComponent = (sum: number, type: CurrencyType) => (
    <SumToPay>{currency(sum, type).format()}</SumToPay>
  )

  if (cardStatusDisplayed === ProductsModel.CardStatusDisplayed.New) {
    return {
      text: 'Карта выпускается',
      icon: <TimeIcon color="icons/main" size="s8" />,
    }
  }
  if (cardStatusDisplayed === ProductsModel.CardStatusDisplayed.NotActive) {
    return {
      text: 'Карта не активирована',
      buttonText: 'Активировать карту',
      buttonClick: () => console.log('click'),
      icon: <AboutSecurityIcon color="icons/main" size="s8" />,
    }
  }
  if (cardStatusDisplayed === ProductsModel.CardStatusDisplayed.Blocked) {
    return {
      text: 'Вы заблокировали карту',
      buttonText: 'Разблокировать',
      buttonClick: () => console.log('click'),
      icon: <FreezeIcon color="icons/main" size="s8" />,
    }
  }
  if (cardStatusDisplayed === ProductsModel.CardStatusDisplayed.BlockedByBank) {
    return {
      text: 'Карта заблокирована банком',
      buttonText: 'Чат поддержки',
      buttonClick: () => console.log('click'),
      icon: <LockIcon color="icons/main" size="s8" />,
    }
  }
  if (cardStatusDisplayed === ProductsModel.CardStatusDisplayed.Closed) {
    return {
      text: 'Карта закрыта',
      buttonText: 'Скрыть',
      buttonClick: () => console.log('click'),
      icon: <StopIcon color="icons/main" size="s8" />,
    }
  }

  /**
   * Кредитная карта TW
   */

  const { creditCardTWGuiStatus, daysLeft } = product.creditCardTWGuiData

  if (
    creditCardTWGuiStatus ===
      ProductsModel.CreditCardTWGuiStatus.PaymentRecommendedHirePurchaseBeforeMinPayment ||
    creditCardTWGuiStatus === ProductsModel.CreditCardTWGuiStatus.PaymentRecommendedNoHirePurchase
  ) {
    return {
      text: (
        <>
          {`Платеж через ${daysLeft} ${pluralize(daysLeft, ['день', 'дня', 'дней'])} на сумму `}
          {currencyComponent(product.contract.properties.sumToPay, product.currencyType)}
        </>
      ),
      buttonText: 'Внести платёж',
      buttonClick: () => console.log('Внести платёж'),
    }
  }

  if (
    creditCardTWGuiStatus ===
      ProductsModel.CreditCardTWGuiStatus.PaymentDayHirePurchaseBeforeMinPayment ||
    creditCardTWGuiStatus === ProductsModel.CreditCardTWGuiStatus.PaymentDayNoHirePurchase
  ) {
    return {
      text: (
        <>
          {'Сегодня день платежа на сумму '}
          {currencyComponent(product.contract.properties.sumToPay, product.currencyType)}
        </>
      ),
      buttonText: 'Внести платёж',
      buttonClick: () => console.log('Внести платёж'),
    }
  }

  if (
    creditCardTWGuiStatus ===
    ProductsModel.CreditCardTWGuiStatus.TooEarlyToPayHirePurchaseAfterMinPayment
  ) {
    return {
      text: (
        <>
          {'Минимальный платеж оплачен, для полного погашения осталось внести '}
          {currencyComponent(product.contract.properties.sumToPay, product.currencyType)}
        </>
      ),
      buttonText: 'Внести платёж',
      buttonClick: () => console.log('Внести платёж'),
    }
  }

  if (
    creditCardTWGuiStatus ===
    ProductsModel.CreditCardTWGuiStatus.PaymentRecommendedHirePurchaseAfterMinPayment
  ) {
    return {
      text: (
        <>
          {`Следующий плажет через ${daysLeft} ${pluralize(daysLeft, [
            'день',
            'дня',
            'дней',
          ])}. Остаток
          по кредиту `}
          {currencyComponent(product.contract.properties.sumToPay, product.currencyType)}
        </>
      ),
      buttonText: 'Внести платёж',
      buttonClick: () => console.log('Внести платёж'),
    }
  }

  if (
    creditCardTWGuiStatus ===
    ProductsModel.CreditCardTWGuiStatus.PaymentDayHirePurchaseAfterMinPayment
  ) {
    return {
      text: (
        <>
          {'Следующий платеж сегодня. Остаток по кредиту '}
          {currencyComponent(product.contract.properties.sumToPay, product.currencyType)}
        </>
      ),
      buttonText: 'Внести платёж',
      buttonClick: () => console.log('Внести платёж'),
    }
  }

  if (creditCardTWGuiStatus === ProductsModel.CreditCardTWGuiStatus.OverduePayment) {
    return {
      text: (
        <>
          Платеж на сумму{' '}
          {currencyComponent(product.contract.properties.sumToPay, product.currencyType)}
          {' просрочен'}
        </>
      ),
      buttonText: 'Внести платёж',
      buttonClick: () => console.log('Внести платёж'),
    }
  }
  return null
}

export type ProductItemInstalmentCardProps = {
  className?: string
  product: ProductsModel.CreditCard
  isEdit?: boolean
  isHidden?: boolean
  toggleHidden: (widgetId: ProductsModel.CreditCard['settingWidgetId']) => void
  onClick: () => void
  isHideDevelopmentInProgress: boolean
}

export const ProductItemInstalmentCard: React.FC<ProductItemInstalmentCardProps> = (props) => {
  const { product, isEdit, isHidden, toggleHidden, onClick, isHideDevelopmentInProgress } = props
  const context = useAppContext<AppContext>()
  const infoSlotData = useInfoSlot(props.product, onClick, isHideDevelopmentInProgress)

  const onToggleHidden = (e: SyntheticEvent) => {
    e.preventDefault()
    toggleHidden(product.settingWidgetId)
  }

  if (isHideDevelopmentInProgress) {
    return (
      <ProductPlate
        onClick={onClick}
        name={product.productName}
        bgColor={product.bgColor}
        bgColorTheme={product.bgColorTheme}
        description={product.description}
        paymentSystem={product.paymentSystem}
        lastFourCardDigits={product.lastFourCardDigits}
        amount={product.availableBalance}
        currencyType={product.currencyType}
        infoSlot={infoSlotData && <InfoSlot {...infoSlotData} />}
        isEdit={isEdit}
        isHidden={isHidden}
        toggleHidden={onToggleHidden}
      />
    )
  }

  return (
    <Link href={context.routes.card(product.id)} passHref>
      <ProductPlate
        name={product.productName}
        bgColor={product.bgColor}
        bgColorTheme={product.bgColorTheme}
        description={product.description}
        paymentSystem={product.paymentSystem}
        lastFourCardDigits={product.lastFourCardDigits}
        amount={product.availableBalance}
        currencyType={product.currencyType}
        infoSlot={infoSlotData && <InfoSlot {...infoSlotData} />}
        isEdit={isEdit}
        isHidden={isHidden}
        toggleHidden={onToggleHidden}
      />
    </Link>
  )
}
