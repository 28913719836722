import { GetContractInfoMFOModel } from '@/api/products/getContractInfoMFO/GetContractInfoMFOModel'
import React, { FC, useState } from 'react'
import { MFOPlate } from '../../../components/MFOPlate'
import { IconWrapper } from '@/components/IconWrapper'
import { color } from '@platform-ui/core'
import { Attention2Icon, TransferByCashIcon } from '@platform-ui/icons'
import { InfoSlot } from '../../../components/InfoSlot'
import { Currency } from '@/components/Currency'
import { getAmount, getPaymentText } from '../utils'
import { useRouter } from '@/next/router'
import { useAppContext } from '@/core'
import { AppContext } from '@/models/AppContext'
import { PaymentModal } from '../PaymentModal'

type Props = {
  contract: GetContractInfoMFOModel.Contract
  serverDate: string
  refetchContractInfo: () => void
}

const TITLE_MAP = {
  homerContract: 'Заём на товар',
  homerCash: 'Заём наличными',
  default: 'Заём',
}

export const CreditItem: FC<Props> = ({ contract, serverDate, refetchContractInfo }) => {
  const router = useRouter()
  const context = useAppContext<AppContext>()

  const { type, interestRate, partnerInfo, status, guiStatus, wallet, remainingDebt } = contract

  const title = `${TITLE_MAP[type] || TITLE_MAP.default} ${interestRate}%`

  const amount = getAmount(contract)

  const paymentText = getPaymentText(contract, serverDate)

  const paymentTextVisible = !(
    status === GetContractInfoMFOModel.StatusCode.Closed ||
    guiStatus === GetContractInfoMFOModel.GuiStatus.TooEarlyToPay ||
    guiStatus === GetContractInfoMFOModel.GuiStatus.NoPaymentSchedule ||
    guiStatus === GetContractInfoMFOModel.GuiStatus.PaymentInAdvance ||
    guiStatus === GetContractInfoMFOModel.GuiStatus.PaymentDebitedToday ||
    guiStatus === GetContractInfoMFOModel.GuiStatus.NotSet
  )

  const debt =
    guiStatus === GetContractInfoMFOModel.GuiStatus.OverduePayment ||
    status === GetContractInfoMFOModel.StatusCode.Demanded

  const [isOpen, setIsopen] = useState(false)

  const paymanetAmount = remainingDebt - wallet > 0 ? remainingDebt - wallet : 0

  return (
    <>
      <MFOPlate
        key={contract.contractId}
        name={title}
        partnerLogoUrl={partnerInfo?.LogoUrl || ''}
        amount={amount}
        currencyType="RUB"
        icon={
          <IconWrapper
            backgroundColor={color['icons/colors/brightTintTwenty']}
            textColor="text/tertiary"
          >
            <TransferByCashIcon color="icons/shape" size="s12" />
          </IconWrapper>
        }
        onClick={() => {
          router.push(context.routes.mfoContract(contract.contractId))
        }}
        infoSlot={
          paymentTextVisible && paymentText ? (
            <InfoSlot
              icon={debt && <Attention2Icon color="icons/colors/error" size="s12" />}
              text={
                <>
                  {paymentText} <Currency amount={paymanetAmount} type="RUB" />
                </>
              }
              buttonText="Внести платеж"
              buttonClick={() => {
                setIsopen(true)
              }}
              textColor="text/tertiary"
            />
          ) : null
        }
      />
      {isOpen && (
        <PaymentModal
          contractId={contract.contractId}
          amount={paymanetAmount}
          isOpen={isOpen}
          onClose={() => {
            refetchContractInfo()
            setIsopen(false)
          }}
          contractType={type}
          recipient={contract?.partnerInfo?.PartnerName || ''}
        />
      )}
    </>
  )
}
