import { ResponseType } from '@/models/ResponseType'
import { createSelector } from 'reselect'
import { UseQueryResult } from 'react-query'
import { SystemError } from '@/models/SystemError'
import { ApiStatus } from '@/core'
import {
  GetApprovalContractsModel,
  ProductType,
  useGetApprovalContractsQuery,
} from '@/api/approvalContracts/getApprovalContracts'

type State = {
  approvalContracts: UseQueryResult<
    GetApprovalContractsModel.ResponseData,
    GetApprovalContractsModel.ErrorResponse
  >
}

export const approvalContractsSelector = createSelector(
  (state: State) => state.approvalContracts.data,
  (state: State) => state.approvalContracts.status,
  (state: State) => state.approvalContracts.refetch,
  (
    approvalContractsResponse,
    approvalContractsStatus,
    approvalContractsRefetch
  ): {
    error: SystemError<'NETWORK' | 'PARTIAL_RESPONSE' | 'IS_EMPTY_PRODUCTS'> | null
    approvalDebitCards: GetApprovalContractsModel.ApprovalContract[]
    approvalCreditCards: GetApprovalContractsModel.ApprovalContract[]
    approvalCredits: GetApprovalContractsModel.ApprovalContract[]
    status: ApiStatus
    retry: () => void
  } => {
    const retryFns: (() => void)[] = []

    let approvalDebitCards: GetApprovalContractsModel.ApprovalContract[] = []
    let approvalCreditCards: GetApprovalContractsModel.ApprovalContract[] = []
    let approvalCredits: GetApprovalContractsModel.ApprovalContract[] = []

    let hasNetworkError = false
    let status = ApiStatus.Idle

    if (approvalContractsStatus === 'success' || approvalContractsStatus === 'error') {
      status = ApiStatus.Fulfilled
    }

    if (approvalContractsStatus === 'error') {
      status = ApiStatus.Rejected
    }

    if (approvalContractsStatus === 'loading') {
      status = ApiStatus.Pending
    }

    if (approvalContractsStatus === 'error') {
      hasNetworkError = true
    }

    if (approvalContractsStatus === 'error') {
      retryFns.push(approvalContractsRefetch)
    }

    if (approvalContractsResponse) {
      if (approvalContractsResponse.type === ResponseType.ResolveWithData) {
        approvalDebitCards = approvalDebitCards.concat(
          ...approvalContractsResponse.result.filter(
            (contract) => contract.ProductType === ProductType.debitCard
          )
        )
        approvalCreditCards = approvalCreditCards.concat(
          ...approvalContractsResponse.result.filter(
            (contract) =>
              contract.ProductType === ProductType.creditCardRd ||
              contract.ProductType === ProductType.creditCardGr
          )
        )
        approvalCredits = approvalCredits.concat(
          ...approvalContractsResponse.result.filter(
            (contract) =>
              (contract.ProductType === ProductType.creditSc &&
                contract.ProductSubtype !== 'MFO') ||
              contract.ProductType === ProductType.topUp ||
              contract.ProductType === ProductType.creditRf ||
              contract.ProductType === ProductType.creditSs ||
              contract.ProductType === ProductType.microCash
          )
        )
      } else {
        retryFns.push(approvalContractsRefetch)
      }
    }

    const error: SystemError<'NETWORK' | 'PARTIAL_RESPONSE' | 'IS_EMPTY_PRODUCTS'> | null =
      hasNetworkError
        ? {
            type: 'NETWORK',
            message: 'При получении данных произошла ошибка',
          }
        : null

    return {
      error: error,
      approvalDebitCards,
      approvalCreditCards,
      approvalCredits,
      status,
      retry: () => {
        retryFns.forEach((item) => {
          item()
        })
      },
    }
  }
)

export function useGetApprovalContracts(approvalContractsType: number) {
  const approvalContracts = useGetApprovalContractsQuery({ approvalContractsType })

  return approvalContractsSelector({ approvalContracts })
}
