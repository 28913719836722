import styled from '@emotion/styled'

export const BlockIconListTitle = styled.div<{}>(({}) => ({
  fontSize: '20px',
  fontWeight: '700',
  marginBottom: '8px',
}))

export const BlockIconListContentIcon = styled.div<{ image: string }>(({ theme, image }) => ({
  minWidth: '48px',
  minHeight: '48px',
  background: `url(${image}) no-repeat`,
  backgroundSize: 'contain',
  backgroundPosition: 'center',
}))
