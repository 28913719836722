import styled from '@emotion/styled'
import { ChevronRightIcon } from '@platform-ui/icons'

export const BlockLinkTextTitle = styled.div<{}>(({}) => ({
  fontSize: '20px',
  fontWeight: '700',
  marginBottom: '8px',
}))

export const BlockLinkTextContentContainer = styled.div<{}>(({ theme }) => ({
  height: '72px',
  display: 'flex',
  alignItems: 'center',
  marginBottom: '12px',
  borderBottomColor: 'lightgray',
  borderBottomWidth: '1px',
  borderBottomStyle: 'solid',
  ':last-child': {
    borderBottomStyle: 'unset',
  },
}))

export const BlockLinkTextContentIcon = styled.div<{ image: string }>(({ theme, image }) => ({
  width: '48px',
  height: '48px',
  background: `url(${image}) no-repeat`,
  backgroundSize: 'contain',
}))

export const BlockLinkTextContentText = styled.a<{ textColor: string }>(({ theme, textColor }) => ({
  height: '48px',
  fontSize: '16px',
  fontWeight: '600',
  display: 'flex',
  alignItems: 'center',
  color: textColor,
  marginLeft: '16px',
  textDecoration: 'none',
}))

export const TabListDialogMoreRightIcon = styled(ChevronRightIcon)(() => ({
  marginLeft: 'auto',
}))
