import { useEffect } from 'react'

export const useCustomScrollRestoration: () => void = () => {
  useEffect(() => {
    if (typeof window !== 'undefined' && 'scrollRestoration' in window.history) {
      window.scrollTo(0, 0)
      window.history.scrollRestoration = 'manual'

      return () => {
        window.history.scrollRestoration = 'auto'
      }
    }
  }, [])
  return null
}
