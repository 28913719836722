import { MutateOptions, useMutation } from 'react-query'
import { ApiManager, ApiManagerModel, HttpClient } from '@/core'
import { ApiState } from '@/models/ApiState'
import { adapter } from './adapters/adapter'
import { Q5fUrlModel } from './Q5fUrlModel'

export const q5fUrl: Q5fUrlModel.Func = async (props) => {
  const { path, control } = ApiManager.getState<ApiState, ApiManagerModel.ApiOption>(
    (state) => state.onlineRequest.onlineRequestUrl,
    {} as ApiManagerModel.ApiOption
  )

  const response = await HttpClient.post<
    Q5fUrlModel.ResponseData,
    void,
    Q5fUrlModel.Requestdata,
    Q5fUrlModel.RequestHeaders,
    Q5fUrlModel.ErrorResponse
  >({
    url: path,
    headers: null,
    responseAdapter: adapter,
    data: {
      productType: props.productType,
      canal: props.canal,
      systemType: props.systemType,
      appVersion: props.appVersion,
      productIdent: props.productIdent,
    },
    control,
  })

  return response
}

export function useQ5fUrlMutation(
  options?: MutateOptions<Q5fUrlModel.Response, Q5fUrlModel.ErrorResponse, Q5fUrlModel.Props>
) {
  return useMutation(q5fUrl, options)
}
