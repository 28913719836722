import styled from '@emotion/styled'
import { Root } from '../InfoSlot/InfoSlot.styled'

const Container = styled.div(({ theme }) => ({
  borderRadius: theme.borderRadius.r10,
  padding: '16px',

  [`${theme.media.md}`]: {
    padding: '20px',
  },
}))

export const ProductPlateContent = styled.div(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: 16,
}))

export const ProductPlateLeft = styled.div(({ theme }) => ({
  width: '100%',
}))

export const ProductPlateTextPrimary = styled.div(({ theme }) => ({
  ...theme.typography['headline/16'],
  marginBottom: '2px',
  color: theme.color['text/main'],

  [`${theme.media.md}`]: {
    ...theme.typography['headline/20'],
    marginBottom: '2px',
    color: theme.color['text/main'],
  },
}))

export const ProductPlateTextSecondary = styled.div(({ theme }) => ({
  ...theme.typography['body/14'],
  color: theme.color['text/mainTransparent'],
  display: 'flex',
  [`${theme.media.md}`]: {
    ...theme.typography['body/16'],
  },
}))

export const ProductPlateLeftIcon = styled.div(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flex: 'none',
  marginRight: '12px',
  fontSize: 0,

  [`${theme.media.md}`]: {
    marginRight: '16px',
  },
}))

export const ProductPlateWrapper = styled(Container)(({ theme }) => ({
  background: theme.color['surface/tertiary'],
  cursor: 'pointer',
}))

export const InfoSlotWrapper = styled.div(({ theme }) => ({
  marginTop: '12px',
  borderTop: `1px solid ${theme.color['surface/line']}`,

  [`${Root}`]: {
    color: theme.color['icons/main'],
  },

  [`${theme.media.md}`]: {
    marginTop: '16px',
  },
}))

export const ArrowIcon = styled.span(({ theme }) => ({
  paddingLeft: '8px',
}))
