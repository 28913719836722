export const BLOCK_TYPE_HTML_TEXT = 'html_text'
export const BLOCK_TYPE_LINK_TEXT = 'link_text'
export const BLOCK_TYPE_UNNUMBERED_LIST = 'unnumbered_list'
export const BLOCK_TYPE_NUMBERED_LIST = 'numbered_list'
export const BLOCK_TYPE_PARTNERS = 'partners'
export const BLOCK_TYPE_DIGITAL_WALLET = 'digital_wallet'
export const BLOCK_TYPE_ICON_LIST = 'icon_list'
export const BLOCK_TYPE_BUTTON = 'button'
export const BLOCK_TYPE_HEADER_H1 = 'header_h1'
export const BLOCK_TYPE_MAIN_ADVANTAGES = 'main_advantages'
