import { ResponseType } from '@/models/ResponseType'
import { createSelector } from 'reselect'
import { UseQueryResult } from 'react-query'
import { SystemError } from '@/models/SystemError'
import { ApiStatus } from '@/core'
import { CcSignModel, useCcSignQuery } from '@/api/approvalContracts/ccSign'

type State = {
  ccSignUrl: UseQueryResult<CcSignModel.ResponseData, CcSignModel.ErrorResponse>
}

export const ccSignSelector = createSelector(
  (state: State) => state.ccSignUrl.data,
  (state: State) => state.ccSignUrl.status,
  (state: State) => state.ccSignUrl.refetch,
  (
    CcSignResponse,
    CcSignStatus,
    CcSignRefetch
  ): {
    error: SystemError<'NETWORK' | 'PARTIAL_RESPONSE' | 'IS_EMPTY_PRODUCTS'> | null
    signUrl: string
    status: ApiStatus
    retry: () => void
  } => {
    const retryFns: (() => void)[] = []
    let url = ''

    let hasNetworkError = false
    let status = ApiStatus.Idle

    if (CcSignStatus === 'success' || CcSignStatus === 'error') {
      status = ApiStatus.Fulfilled
    }

    if (CcSignStatus === 'error') {
      status = ApiStatus.Rejected
    }

    if (CcSignStatus === 'loading') {
      status = ApiStatus.Pending
    }

    if (CcSignStatus === 'error') {
      hasNetworkError = true
    }

    if (CcSignStatus === 'error') {
      retryFns.push(CcSignRefetch)
    }

    if (CcSignResponse) {
      if (CcSignResponse.type === ResponseType.ResolveWithData) {
        url = CcSignResponse.result.url
      } else {
        retryFns.push(CcSignRefetch)
      }
    }

    const error: SystemError<'NETWORK' | 'PARTIAL_RESPONSE' | 'IS_EMPTY_PRODUCTS'> | null =
      hasNetworkError
        ? {
            type: 'NETWORK',
            message: 'При получении данных произошла ошибка',
          }
        : null

    return {
      error: error,
      signUrl: url,
      status,
      retry: () => {
        retryFns.forEach((item) => {
          item()
        })
      },
    }
  }
)

export function useCcSign(contractNumber: string, isEnabled: boolean) {
  const signUrl = useCcSignQuery({ contractNumber }, { enabled: isEnabled })

  return ccSignSelector({ ccSignUrl: signUrl })
}
