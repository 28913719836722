import { ResponseType } from '@/models/ResponseType'
import { createSelector } from 'reselect'
import { UseQueryResult } from 'react-query'
import { SystemError } from '@/models/SystemError'
import { ApiStatus } from '@/core'
import {
  DebitCardRegistryModel,
  useDebitCardRegistryQuery,
} from '@/api/approvalContracts/debitCardRegistry'

type State = {
  debitSignUrl: UseQueryResult<
    DebitCardRegistryModel.ResponseData,
    DebitCardRegistryModel.ErrorResponse
  >
}

export const debitCardRegistrySelector = createSelector(
  (state: State) => state.debitSignUrl.data,
  (state: State) => state.debitSignUrl.status,
  (state: State) => state.debitSignUrl.refetch,
  (
    debitCardRegistryResponse,
    debitCardRegistryStatus,
    debitCardRegistryRefetch
  ): {
    error: SystemError<'NETWORK' | 'PARTIAL_RESPONSE' | 'IS_EMPTY_PRODUCTS'> | null
    signUrl: string
    status: ApiStatus
    retry: () => void
  } => {
    const retryFns: (() => void)[] = []
    let url = ''

    let hasNetworkError = false
    let status = ApiStatus.Idle

    if (debitCardRegistryStatus === 'success' || debitCardRegistryStatus === 'error') {
      status = ApiStatus.Fulfilled
    }

    if (debitCardRegistryStatus === 'error') {
      status = ApiStatus.Rejected
    }

    if (debitCardRegistryStatus === 'loading') {
      status = ApiStatus.Pending
    }

    if (debitCardRegistryStatus === 'error') {
      hasNetworkError = true
    }

    if (debitCardRegistryStatus === 'error') {
      retryFns.push(debitCardRegistryRefetch)
    }

    if (debitCardRegistryResponse) {
      if (debitCardRegistryResponse.type === ResponseType.ResolveWithData) {
        url = debitCardRegistryResponse.result.url
      } else {
        retryFns.push(debitCardRegistryRefetch)
      }
    }

    const error: SystemError<'NETWORK' | 'PARTIAL_RESPONSE' | 'IS_EMPTY_PRODUCTS'> | null =
      hasNetworkError
        ? {
            type: 'NETWORK',
            message: 'При получении данных произошла ошибка',
          }
        : null

    return {
      error: error,
      signUrl: url,
      status,
      retry: () => {
        retryFns.forEach((item) => {
          item()
        })
      },
    }
  }
)

export function useDebitCardRegistry(ContractNumber: string, Product: number, isEnabled: boolean) {
  const signUrl = useDebitCardRegistryQuery({ ContractNumber, Product }, { enabled: isEnabled })

  return debitCardRegistrySelector({ debitSignUrl: signUrl })
}
