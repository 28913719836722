import React from 'react'
import { observer } from 'mobx-react-lite'
import { OffersModel } from '../../../../api/products/offers/OffersModel'
import { OfferPlate } from '../../components/OfferPlate'
import { argbHexToRgba } from '../../../../utils/colorHelper'

export type ProductItemOfferProps = {
  product: OffersModel.OfferWidget
}

export const ProductItemOffer: React.FC<ProductItemOfferProps> = observer((props) => {
  const { product } = props

  return (
    <OfferPlate
      templateId={product.templateId}
      title={product.title}
      subtitle={product.subtitle}
      description={product.description}
      backgroundColor={product.backgroundColor}
      imageUrl={product.imageUrlWeb}
      icon={product.icon}
      textColor={argbHexToRgba(product.textColor)}
    />
  )
})
