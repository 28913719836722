import React from 'react'
import { OfferContentModel } from '../../../../api/products/offerContent/OffersModel'
import {
  BlockPartnersContentContainer,
  BlockPartnersContentIcon,
  BlockPartnersContentItem,
  BlockPartnersContentText,
  BlockPartnersTitle,
} from './styled'

export const BlockPartners = ({ blockContent }) => {
  return (
    <>
      <BlockPartnersTitle>{blockContent.title}</BlockPartnersTitle>
      <BlockPartnersContentContainer>
        {blockContent.content.map((content: OfferContentModel.BlockContent) => {
          return (
            <>
              <BlockPartnersContentItem>
                <BlockPartnersContentIcon image={content.icon} />
                <BlockPartnersContentText textColor="black">
                  {content.title}
                </BlockPartnersContentText>
                <BlockPartnersContentText textColor="black">
                  {content.subtitle}
                </BlockPartnersContentText>
              </BlockPartnersContentItem>
            </>
          )
        })}
      </BlockPartnersContentContainer>
    </>
  )
}
