import React, { useEffect } from 'react'
import { OfferContentModel } from '../../api/products/offerContent/OffersModel'
import { useOfferContent } from '../product-lists/hooks/useOfferContent'
import { Button } from '@platform-ui/components/Button'
import { OfferContentAdvantagesContainer } from './components/ContentAdvantages/ContentAdvantages'
import { DialogButton, OfferContentContainer } from './styled'
import { OfferContentBlock } from './components/OfferContentBlock/OfferContentBlock'
import { useOfferProducts } from '../product-lists/hooks/useOfferProducts'
import { useFormState } from './useFormState'
import { OfferProcess } from './components/OfferProcess/OfferProcess'
import { BLOCK_TYPE_BUTTON } from './constants'
import { OfferContentError } from './components/OfferContentError/OfferContentError'
import { OffersModel } from '../../api/products/offers/OffersModel'

export type OfferContentDetailProps = {
  templateId: number
  onClose?: () => void
  isMobile: boolean
}

function ReplaceText(textValue: string, individualParams: OffersModel.IndividualParam): string {
  return textValue == undefined
    ? textValue
    : textValue
        .replace('{limit}', individualParams.limit || '{limit}')
        .replace('{term}', individualParams.term || '{term}')
        .replace('{rate}', individualParams.rate || '{rate}')
        .replace('{monthPayment}', individualParams.monthPayment || '{monthPayment}')
}

export const OfferContentDetail = ({ templateId, onClose, isMobile }: OfferContentDetailProps) => {
  const { error, productIds, products, status, retry, hasAnyResult } = useOfferContent()
  const offers = useOfferProducts()

  const { handleClick, storeUrl } = useFormState()

  var buttonBlock
  var detail
  var productById = products[templateId] as OfferContentModel.Content
  if (productById) {
    buttonBlock =
      productById.blocks.find((x) => x.type == BLOCK_TYPE_BUTTON) || ({} as OfferContentModel.Block)
    detail = offers.offerDetails[templateId] as OffersModel.OfferDetail
    productById.blocks.map((block) => {
      block.text = ReplaceText(block.text, detail.individualParams)
      block.title = ReplaceText(block.title, detail.individualParams)
      block.subtitle = ReplaceText(block.subtitle, detail.individualParams)
      if (block.content != undefined) {
        block.content.map((content) => {
          content.text = ReplaceText(content.text, detail.individualParams)
          content.title = ReplaceText(content.title, detail.individualParams)
          content.subtitle = ReplaceText(content.subtitle, detail.individualParams)
        })
      }
    })
  }

  return (
    <>
      {storeUrl ? (
        <OfferProcess onClose={onClose} processUrl={storeUrl}></OfferProcess>
      ) : productById ? (
        <>
          <OfferContentContainer>
            <OfferContentAdvantagesContainer
              productById={productById}
              isMobile={isMobile}
              onClose={onClose}
            ></OfferContentAdvantagesContainer>
            <OfferContentBlock
              productById={productById}
              isMobile={isMobile}
              onClose={onClose}
            ></OfferContentBlock>
          </OfferContentContainer>
          <DialogButton>
            <Button
              isFullWidth
              onClick={() => {
                handleClick(detail)
              }}
            >
              {buttonBlock?.text}
            </Button>
          </DialogButton>
        </>
      ) : (
        <OfferContentError onClose={onClose}></OfferContentError>
      )}
    </>
  )
}
