import React, { forwardRef, useState } from 'react'
import { Dialog } from '@platform-ui/components/Dialog'
import { MarketplaceOffer, OfferDescription, OfferSubtitle, OfferTitle } from './styled'
import { useAppContext } from '@/core'
import { AppContext } from '@/models/AppContext'
import { Screen } from '@platform-ui/components/Screen'
import { OfferProcess } from '../../../offer-content/components/OfferProcess/OfferProcess'

type MarketplaceOfferPlateProps = Readonly<{
  templateId: number
  title: string
  subtitle: string
  description: string
  backgroundColor: string
  secondBackgroundColor: string
  textColor: string
  icon: string
  imageUrl: string
  url: string
  onClick?: () => void
}>

export const MarketplaceOfferPlate = forwardRef<HTMLAnchorElement, MarketplaceOfferPlateProps>(
  (props) => {
    const onClick = () => {
      setIsOpenModal(true)
    }

    const onCloseModal = () => {
      setIsOpenModal(false)
    }

    const { config } = useAppContext<AppContext>()
    const isMobile = config.device.isMobile

    const [isOpenModal, setIsOpenModal] = useState(false)

    const {
      backgroundColor,
      secondBackgroundColor,
      imageUrl,
      title,
      subtitle,
      description,
      textColor,
      icon,
      templateId,
      url,
    } = props

    return (
      <>
        <MarketplaceOffer
          backgroundColor={backgroundColor}
          secondBackgroundColor={secondBackgroundColor}
          imageUrl={imageUrl}
          icon={icon}
          onClick={onClick}
        >
          <OfferTitle textColor={textColor}>{title}</OfferTitle>
          <OfferSubtitle textColor={textColor}>{subtitle}</OfferSubtitle>
          <OfferDescription textColor={textColor}>{description}</OfferDescription>
        </MarketplaceOffer>

        {isMobile ? (
          <Screen isOpen={isOpenModal} onClose={onCloseModal}>
            <OfferProcess onClose={onCloseModal} processUrl={url}></OfferProcess>
          </Screen>
        ) : (
          <Dialog isOpen={isOpenModal} onClose={onCloseModal} smMaxBoxSize={'s100'}>
            <OfferProcess onClose={onCloseModal} processUrl={url}></OfferProcess>
          </Dialog>
        )}
      </>
    )
  }
)
