import styled from '@emotion/styled'
import React, { FC } from 'react'
import { ProductError } from '../../../components/ProductError'
import { ProductList } from '../../../components/ProductList'
import { ProductShimmer } from '../../../components/ProductShimmer'
import { GetCreditLinesMFOModel } from '@/api/products/mfo/getCreditLinesMFO'
import { CreditLineItem } from './CreditLineItem'

const ErrorContainer = styled.div`
  margin-bottom: 12px;
`

type Props = {
  error: boolean
  contracts: GetCreditLinesMFOModel.CreditLine[]
  refetchContractInfo: () => void
  loading: boolean
  serverDate: string
}

export const CreditLineContainer: FC<Props> = ({
  error,
  contracts,
  refetchContractInfo,
  loading,
  serverDate,
}) => {
  if (error) {
    return (
      <ErrorContainer>
        <ProductError
          message="Не удалось получить информацию по кредитным линиям."
          retryClick={refetchContractInfo}
        />
      </ErrorContainer>
    )
  }

  if (!contracts?.length && !loading) {
    return null
  }

  return (
    <ProductList title="Кредитные линии" isLoading={loading}>
      {loading ? (
        <ProductShimmer />
      ) : (
        contracts.map((contract) => (
          <CreditLineItem
            key={contract.contractId}
            contract={contract}
            serverDate={serverDate}
            refetchContractInfo={refetchContractInfo}
          />
        ))
      )}
    </ProductList>
  )
}
