import styled from '@emotion/styled'
import React, { FC } from 'react'
import { ProductError } from '../../../components/ProductError'
import { GetContractInfoMFOModel } from '@/api/products/getContractInfoMFO/GetContractInfoMFOModel'
import { ProductList } from '../../../components/ProductList'
import { ProductShimmer } from '../../../components/ProductShimmer'
import { CreditItem } from './CreditItem'

const ErrorContainer = styled.div`
  margin-bottom: 12px;
`

type Props = {
  error: boolean
  contracts: GetContractInfoMFOModel.Contract[]
  refetchContractInfo: () => void
  loading: boolean
  serverDate: string
}

export const CreditContainer: FC<Props> = ({
  error,
  contracts,
  refetchContractInfo,
  loading,
  serverDate,
}) => {
  if (error) {
    return (
      <ErrorContainer>
        <ProductError
          message="Не удалось получить информацию по заёмам."
          retryClick={refetchContractInfo}
        />
      </ErrorContainer>
    )
  }

  if (!contracts?.length && !loading) {
    return null
  }

  return (
    <ProductList title="Займы" isLoading={loading}>
      {loading ? (
        <ProductShimmer />
      ) : (
        contracts.map((contract) => (
          <CreditItem
            key={contract.contractId}
            contract={contract}
            serverDate={serverDate}
            refetchContractInfo={refetchContractInfo}
          />
        ))
      )}
    </ProductList>
  )
}
