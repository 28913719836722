import React from 'react'
import {
  AdvantagesBlockContentIcon,
  AdvantagesBlockContentText,
  AdvantagesImage,
  AdvantagesItemContainer,
  AdvantagesSubtitle,
  AdvantagesTitle,
  OfferContentAdvantages,
} from './styled'
import { BLOCK_TYPE_HEADER_H1, BLOCK_TYPE_MAIN_ADVANTAGES } from '../../constants'
import { ClearIcon } from '@platform-ui/icons'
import { CloseIconButton } from '../OfferContentBlock/styled'
import { DialogHeaderWrapper } from '../../styled'
import { OfferContentBlockProps } from '../OfferContentBlock/OfferContentBlock'
import { argbHexToRgba } from '../../../../utils/colorHelper'

export const OfferContentAdvantagesContainer = ({
  productById,
  isMobile,
  onClose,
}: OfferContentBlockProps) => {
  var headerAdvantage = productById.blocks.find((x) => x.type == BLOCK_TYPE_HEADER_H1)
  var blockAdvantage = productById.blocks.find((x) => x.type == BLOCK_TYPE_MAIN_ADVANTAGES)

  return (
    <OfferContentAdvantages
      firstBackgroundColor={argbHexToRgba(productById.firstBackgroundColor) as string}
      secondBackgroundColor={argbHexToRgba(productById.secondBackgroundColor) as string}
    >
      {isMobile && (
        <DialogHeaderWrapper>
          <CloseIconButton onClick={onClose}>
            <ClearIcon color="text/main" />
          </CloseIconButton>
        </DialogHeaderWrapper>
      )}

      <AdvantagesTitle textColor={argbHexToRgba(headerAdvantage?.textColor)}>
        {headerAdvantage?.title}
      </AdvantagesTitle>
      <AdvantagesSubtitle textColor={argbHexToRgba(headerAdvantage?.textColor)}>
        {headerAdvantage?.subtitle}
      </AdvantagesSubtitle>
      <AdvantagesImage image={productById.image}></AdvantagesImage>
      {blockAdvantage?.content.map((content) => (
        <AdvantagesBlockContent
          advantageBlockContentItem={content}
          textColor={argbHexToRgba(blockAdvantage?.textColor)}
        ></AdvantagesBlockContent>
      ))}
    </OfferContentAdvantages>
  )
}

export const AdvantagesBlockContent = ({ advantageBlockContentItem, textColor }) => {
  return (
    <div>
      <AdvantagesItemContainer>
        <AdvantagesBlockContentIcon
          image={advantageBlockContentItem.icon}
        ></AdvantagesBlockContentIcon>
        <AdvantagesBlockContentText textColor={textColor}>
          {advantageBlockContentItem.text}
        </AdvantagesBlockContentText>
      </AdvantagesItemContainer>
    </div>
  )
}
