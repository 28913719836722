import React, { forwardRef, useState } from 'react'
import { CheckIcon } from '@platform-ui/icons/CheckIcon'
import {
  ProductPlateContent,
  ProductPlateLeft,
  ProductPlateTextPrimary,
  ProductPlateTextSecondary,
  ProductPlateLeftIcon,
  ProductPlateWrapper,
  ArrowIcon,
} from './styled'
import { Currency, CurrencyProps } from '@/components/Currency'
import { Button } from '@platform-ui/components/Button'
import { CardSignModal } from '../CardSignModal'

type CreditSignProps = Readonly<{
  className?: string
  name: string
  contractNumber: string
  amount: CurrencyProps['amount']
  currencyType: CurrencyProps['type']
  onClick?: React.MouseEventHandler<HTMLAnchorElement>
  icon: React.ReactNode | React.ReactNode[]
}>

export const CreditSign = forwardRef<HTMLAnchorElement, CreditSignProps>((props) => {
  const { amount = 0, name, currencyType, contractNumber, icon: leftIcon } = props
  const [isOpenSign, setIsOpenSign] = useState<boolean>(false)

  const onClose = (): void => {
    setIsOpenSign(false)
  }

  return (
    <>
      <ProductPlateWrapper>
        <ProductPlateContent>
          {leftIcon && <ProductPlateLeftIcon>{leftIcon}</ProductPlateLeftIcon>}

          <ProductPlateLeft>
            <ProductPlateTextPrimary>{name}</ProductPlateTextPrimary>
            <ProductPlateTextSecondary>
              <CheckIcon /> Одобрено
            </ProductPlateTextSecondary>
          </ProductPlateLeft>

          <ProductPlateTextPrimary>
            <Currency amount={amount} type={currencyType} />
          </ProductPlateTextPrimary>
        </ProductPlateContent>
        <Button
          size="m"
          isFullWidth
          view={'on-color'}
          rightIcon={<ArrowIcon>{'>'}</ArrowIcon>}
          iconOffsetSm="space-between"
          onClick={() => setIsOpenSign(true)}
        >
           Получить
        </Button>
      </ProductPlateWrapper>
      <CardSignModal
        cardType="credit"
        contractNumber={contractNumber}
        isOpen={isOpenSign}
        onClose={onClose}
      />
    </>
  )
})
