import { pluralize } from '@/utils/pluralize'
import styled from '@emotion/styled'
import { Button } from '@platform-ui/components/Button'
import { IconButton } from '@platform-ui/components/IconButton'
import { CheckIcon } from '@platform-ui/icons/CheckIcon'
import { SettingsIcon } from '@platform-ui/icons/SettingsIcon'
import { ShowIcon } from '@platform-ui/icons/ShowIcon'
import Link from '@/next/link'
import { useCreditProducts } from '../../hooks/useCreditProducts'
import { useStockpilingProducts } from '../../hooks/useStockpilingProducts'
import { useWalletProducts } from '../../hooks/useWalletProducts'
import { useWidetsSettingsStore } from '../../widgetsSettingsStore/WidgetsSettingsStore'
import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import { useAppContext } from '@/core'

const Root = styled('div')(({ theme }) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '32px',
  }
})

const ButtonCustom = styled(Button)(({ theme }) => {
  return {
    marginBottom: '40px',
  }
})

const IconButtonCustom = styled(IconButton)(() => {
  return {
    display: 'flex',
    flexDirection: 'column',
  }
})

const Content = styled('div')(() => {
  return {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  }
})

const Text = styled('div')(({ theme }) => {
  return {
    ...theme.typography['headline/12'],
    color: theme.color['text/primary'],
    marginTop: '12px',
  }
})

const LinkBox = styled('a')(() => {
  return {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  }
})

export const ButtonsForHiddenProducts = observer(() => {
  const context = useAppContext()

  const { hiddenProductsIds: walletProducts } = useWalletProducts()
  const { hiddenProductsIds: stockpilingProducts } = useStockpilingProducts()
  const { hiddenProductsIds: creditProducts } = useCreditProducts()
  const widgetsSettings = useWidetsSettingsStore()
  const isEdit = widgetsSettings.isEdit

  const countHiddenProducts =
    walletProducts.length + stockpilingProducts.length + creditProducts.length

  const buttonEdit = useMemo(
    () =>
      isEdit ? (
        <ButtonCustom
          view="primary"
          onClick={() => widgetsSettings.offEditModeWithSave()}
          leftIcon={<CheckIcon />}
        >
          Готово
        </ButtonCustom>
      ) : (
        <ButtonCustom
          view="secondary"
          onClick={() => widgetsSettings.onEditMode()}
          leftIcon={<SettingsIcon />}
        >
          Настроить главную
        </ButtonCustom>
      ),
    [isEdit]
  )

  if (countHiddenProducts === 0) {
    return <Root>{buttonEdit}</Root>
  }

  return (
    <Root>
      {buttonEdit}
      <Content>
        <Link href={context.routes['hidden-products']()}>
          <LinkBox>
            <IconButtonCustom type="button" view="secondary">
              <ShowIcon />
            </IconButtonCustom>
            <Text>{`${countHiddenProducts} ${pluralize(countHiddenProducts, [
              'скрытый',
              'скрытых',
              'скрытых',
            ])} ${pluralize(countHiddenProducts, ['счет', 'счета', 'счетов'])}`}</Text>
          </LinkBox>
        </Link>
      </Content>
    </Root>
  )
})
