import React from 'react'
import { OfferContentModel } from '../../../../api/products/offerContent/OffersModel'
import {
  BlockLinkTextContentContainer,
  BlockLinkTextContentIcon,
  BlockLinkTextContentText,
  BlockLinkTextTitle,
  TabListDialogMoreRightIcon,
} from './styled'

export const BlockLinkText = ({ blockContent }) => {
  return (
    <div key={blockContent.priority}>
      <BlockLinkTextTitle>{blockContent.title}</BlockLinkTextTitle>
      <div>
        {blockContent.content.map((content: OfferContentModel.BlockContent) => {
          //Поменять на id
          return (
            <div key={content.priority}>
              <BlockLinkTextContentContainer>
                <BlockLinkTextContentIcon image={content.icon}></BlockLinkTextContentIcon>
                <BlockLinkTextContentText
                  textColor="black"
                  href={content.link}
                  target="_blank"
                  rel="noreferrer"
                >
                  {content.text}
                </BlockLinkTextContentText>
                <TabListDialogMoreRightIcon color="icons/main" />
              </BlockLinkTextContentContainer>
            </div>
          )
        })}
      </div>
    </div>
  )
}
