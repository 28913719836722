import { AdapterModel } from './AdapterModel'
import { ResponseType } from '@/models/ResponseType'
import { RejectedWithSystemErrorResponse } from '@/models/RejectedWithSystemErrorResponse'
import { HttpClient } from '@/core'
import { color } from '@platform-ui/core'
import { GetApprovalContractsModel, ProductType } from '../GetApprovalContractsModel'

export const adapter: AdapterModel.Func = (data) => {
  const errorData = data as AdapterModel.ResponseDataError
  /**
   * Сервер всегда возвращает status=200 в заголовке
   * Реальный статус приходит в теле ответа
   * 401 или 500+ считаем rejected ответами с наличием критической ошибки
   */
  if (errorData?.StatusCode === 401 || errorData?.StatusCode >= 500) {
    const exception: RejectedWithSystemErrorResponse = {
      type: ResponseType.RejectedWithSystemError,
      systemErrors:
        errorData.ErrorDetails?.map(({ Error, Title }) => ({
          type: Error,
          message: Title,
        })) || [],
    }
    throw HttpClient.createError(errorData?.StatusCode, exception)
  }

  try {
    const errorData = data as AdapterModel.ResponseDataError
    /**
     * Есть ошибки от сервера
     */
    if (errorData.Errors.length) {
      return {
        type: ResponseType.ResolveWithSystemError,
        systemErrors: errorData.ErrorDetails.map(({ Error }) => ({
          type: Error,
          message: Error,
        })),
      }
    }

    const successData = data as AdapterModel.ResponseDataSuccess
    const result: GetApprovalContractsModel.ApprovalContract[] = successData.Result.map(
      (contract) => {
        const modifiedContract: GetApprovalContractsModel.ApprovalContract = {
          ...contract,
        }
        if (contract.ProductType === ProductType.debitCard) {
          modifiedContract.bgColor = color['products/rebranding/Polza']
          modifiedContract.bgColorTheme = 'dark'
        }
        if (
          contract.ProductType === ProductType.creditCardRd ||
          contract.ProductType === ProductType.creditCardGr
        ) {
          modifiedContract.bgColor = color['products/rebranding/days']
          modifiedContract.bgColorTheme = 'dark'
        }
        return modifiedContract
      }
    )
    return {
      type: ResponseType.ResolveWithData,
      result,
    }
  } catch (error) {
    const exception: RejectedWithSystemErrorResponse = {
      type: ResponseType.RejectedWithSystemError,
      systemErrors: [{ message: 'Ошибка при обработке полученных данных', type: 'ADAPTER_ERROR' }],
    }

    throw HttpClient.createError(500, exception)
  }
}
