import styled from '@emotion/styled'
import { ITheme } from '@platform-ui/core'

export const MarketplaceOffer = styled.a<{
  backgroundColor: string
  secondBackgroundColor: string
  imageUrl: string
  icon: string
}>(({ theme, backgroundColor, secondBackgroundColor, imageUrl, icon }) => ({
  background: `url(${imageUrl}), linear-gradient(338.53deg, ${secondBackgroundColor} 0%, ${backgroundColor} 100%);`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'right',
  backgroundSize: 'contain',
  borderRadius: 12,
  display: 'flex',
  justifyContent: 'end',
  flexDirection: 'column',
  textDecoration: 'none',
  padding: '0px 120px 16px 16px',
  height: '144px',
  cursor: 'pointer',
  overflow: 'hidden',
  textOverflow: 'clip',
  [`${theme.media.md}`]: {
    paddingBottom: '20px',
    overflow: 'unset',
    textOverflow: 'unset',
  },
}))

export const OfferTitle = styled.p<{ textColor: keyof ITheme['color'] | string }>(
  ({ theme, textColor }) => ({
    ...theme.typography['body/14'],
    color: textColor || 'white',
    fontWeight: '600',
    lineHeight: '16px',

    [`${theme.media.md}`]: {
      ...theme.typography['headline/16'],
      marginBottom: '2px',
    },
  })
)

export const OfferSubtitle = styled.p<{ textColor: keyof ITheme['color'] | string }>(
  ({ theme, textColor }) => ({
    ...theme.typography['body/24'],
    color: textColor || 'white',
    letterSpacing: '-0.3px',
    marginBottom: '4px',
    marginTop: '4px',
    lineHeight: '24px',
    fontWeight: '700',
    fontSize: '20px',

    [`${theme.media.md}`]: {
      ...theme.typography['headline/24'],
      marginBottom: '2px',
      marginTop: '2px',
    },
  })
)

export const OfferDescription = styled.p<{ textColor: keyof ITheme['color'] | string }>(
  ({ theme, textColor }) => ({
    ...theme.typography['body/14'],
    color: textColor || 'white',
    lineHeight: '18px',
    fontWeight: '500',
    fontSize: '14px',

    [`${theme.media.md}`]: {
      ...theme.typography['headline/16'],
    },
  })
)
