import { ResponseType } from '@/models/ResponseType'
import { createSelector } from 'reselect'
import { UseQueryResult } from 'react-query'
import { SystemError } from '@/models/SystemError'
import { ApiStatus } from '@/core'
import _keyBy from 'lodash/keyBy'
import { OfferContentModel } from '@/api/products/offerContent'

type State = {
  offerContent: UseQueryResult<OfferContentModel.ResponseData, OfferContentModel.ErrorResponse>
}
export const offerContentSelector = createSelector(
  (state: State) => state.offerContent.data,
  (state: State) => state.offerContent.status,
  (state: State) => state.offerContent.refetch,
  (
    offersResponse,
    offersStatus,
    offersRefetch
  ): {
    error: SystemError<'NETWORK' | 'PARTIAL_RESPONSE' | 'IS_EMPTY_PRODUCTS'> | null
    productIds: string[]
    products: Record<string, OfferContentModel.Content>
    status: ApiStatus
    retry: () => void
    hasAnyResult: boolean
  } => {
    const retryFns: (() => void)[] = []

    let products: OfferContentModel.Product[] = []

    let hasNetworkError = false
    let hasPartialResponse = false
    let status = ApiStatus.Idle

    if (offersStatus === 'success' || offersStatus === 'error') {
      status = ApiStatus.Fulfilled
    }

    if (offersStatus === 'loading') {
      status = ApiStatus.Pending
    }

    if (offersStatus === 'error') {
      hasNetworkError = true
    }

    if (offersStatus === 'error') {
      retryFns.push(offersRefetch)
    }

    if (offersResponse) {
      if (offersResponse.type === ResponseType.ResolveWithData) {
        products = products.concat(...offersResponse.products)
      } else {
        hasPartialResponse = true
        retryFns.push(offersRefetch)
      }
    }

    const error: SystemError<'NETWORK' | 'PARTIAL_RESPONSE' | 'IS_EMPTY_PRODUCTS'> | null =
      hasNetworkError
        ? {
            type: 'NETWORK',
            message: 'При получении данных произошла ошибка',
          }
        : hasPartialResponse
        ? {
            type: 'PARTIAL_RESPONSE',
            message: 'Ведутся сервисные работы',
          }
        : status === ApiStatus.Fulfilled && !products.length
        ? {
            type: 'IS_EMPTY_PRODUCTS',
            message: 'Список предложений пуст',
          }
        : null

    const hasProducts = products.length > 0
    const productMap = _keyBy(products, function (x) {
      return x.templateId
    })

    return {
      error: error,
      products: productMap,
      productIds: products.map((item) => item.templateId.toString()),
      status,
      retry: () => {
        retryFns.forEach((item) => {
          item()
        })
      },
      hasAnyResult: hasProducts || !!error,
    }
  }
)
