import { GetContractInfoMFOModel } from '@/api/products/getContractInfoMFO/GetContractInfoMFOModel'

const STATUS_PRIORITY_MAP = new Map<GetContractInfoMFOModel.StatusCode, number>([
  [GetContractInfoMFOModel.StatusCode.Demanded, 1],
  [GetContractInfoMFOModel.StatusCode.Active, 2],
  [GetContractInfoMFOModel.StatusCode.Signed, 3],
  [GetContractInfoMFOModel.StatusCode.Closed, 4],
])

const LOWEST_PRIOTITY = 5

export const sortMFOContractsByStatus = (contracts: GetContractInfoMFOModel.Contract[]) => {
  return contracts.sort(
    (a, b) =>
      (STATUS_PRIORITY_MAP.get(a.status) ?? LOWEST_PRIOTITY) -
      (STATUS_PRIORITY_MAP.get(b.status) ?? LOWEST_PRIOTITY)
  )
}
