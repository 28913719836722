import styled from '@emotion/styled'

export const ProductPlateTextPrimary = styled.p(({ theme }) => ({
  ...theme.typography['headline/16'],
  marginBottom: '2px',
  color: theme.color['text/tertiary'],

  [`${theme.media.md}`]: {
    ...theme.typography['headline/20'],
    marginBottom: '2px',
    color: theme.color['text/tertiary'],
  },
}))

export const ProductPlateTextSecondary = styled.p(({ theme }) => ({
  ...theme.typography['body/14'],
  color: theme.color['text/tertiaryTransparent'],

  [`${theme.media.md}`]: {
    ...theme.typography['body/16'],
  },
}))

export const InfoSlotWrapper = styled.div(({ theme }) => ({
  marginTop: '12px',
  borderTop: '1px solid transparent',
  borderColor: theme.color['surface/lightLine'],

  [`${theme.media.md}`]: {
    marginTop: '16px',
  },
}))

export const ProductPlateContent = styled.div(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}))

export const ProductPlateLeft = styled.div(() => ({
  width: '100%',
}))

export const ProductPlateLeftIcon = styled.div(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flex: 'none',
  marginRight: '12px',
  fontSize: 0,

  [`${theme.media.md}`]: {
    marginRight: '16px',
  },
}))

const Container = styled.a(({ theme }) => ({
  display: 'block',
  borderRadius: theme.borderRadius.r10,
  padding: '16px',
  textDecoration: 'none',

  [`${theme.media.md}`]: {
    padding: '20px',
  },
}))

export const ProductPlateWrapper = styled(Container)(({ theme }) => ({
  background: theme.color['products/Zaemi'],
  cursor: 'pointer',
}))

export const PartnerLogo = styled.img`
  max-height: 12px;
`
