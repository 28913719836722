import { forwardRef } from 'react'
import { Currency, CurrencyProps } from '@/components/Currency'
import {
  InfoSlotWrapper,
  ProductPlateTextPrimary,
  ProductPlateContent,
  ProductPlateLeft,
  ProductPlateLeftIcon,
  ProductPlateWrapper,
  PartnerLogo,
} from './MFOPlate.styled'

type MFOPlateProps = {
  className?: string
  name: string
  amount: CurrencyProps['amount']
  currencyType: CurrencyProps['type']
  infoSlot?: React.ReactNode | React.ReactNode[]
  onClick?: React.MouseEventHandler<HTMLAnchorElement>
  icon: React.ReactNode | React.ReactNode[]
  partnerLogoUrl: string
  ariaLabel?: string
}

export const MFOPlate = forwardRef<HTMLAnchorElement, MFOPlateProps>((props, ref) => {
  const {
    className,
    ariaLabel,
    icon: leftIcon,
    name,
    partnerLogoUrl,
    amount,
    currencyType,
    infoSlot,
    ...otherProps
  } = props

  return (
    <ProductPlateWrapper ref={ref} className={className} aria-label={ariaLabel} {...otherProps}>
      <ProductPlateContent>
        {leftIcon && <ProductPlateLeftIcon>{leftIcon}</ProductPlateLeftIcon>}

        <ProductPlateLeft>
          <ProductPlateTextPrimary>{name}</ProductPlateTextPrimary>
          {partnerLogoUrl && <PartnerLogo src={partnerLogoUrl} alt="partner logo" />}
        </ProductPlateLeft>

        <ProductPlateTextPrimary>
          <Currency amount={amount} type={currencyType} />
        </ProductPlateTextPrimary>
      </ProductPlateContent>

      {infoSlot && <InfoSlotWrapper>{infoSlot}</InfoSlotWrapper>}
    </ProductPlateWrapper>
  )
})
