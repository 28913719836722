import { GetContractInfoMFOModel } from '@/api/products/getContractInfoMFO/GetContractInfoMFOModel'

export const getAmount = (contract: GetContractInfoMFOModel.Contract): number => {
  if (contract.status === GetContractInfoMFOModel.StatusCode.Closed) {
    return 0
  }

  if (contract.status === GetContractInfoMFOModel.StatusCode.Signed) {
    return contract.amount
  }

  return contract.principal
}
