import { HttpClientModel } from '@/core'
import { ResolveWithSystemErrorResponse } from '@/models/ResolveWithSystemErrorResponse'
import { RejectedWithSystemErrorResponse } from '@/models/RejectedWithSystemErrorResponse'
import { ResponseType } from '@/models/ResponseType'

export namespace SaveResponseModel {
  export type ClientOfferResponse = {
    OfferId?: string
    ClickId?: string
    SessionId?: string
    Type: ClientResponseTypeEnum
  }
  export type RequestData = {
    Responses: ClientOfferResponse[]
  }
  export type Props = ClientOfferResponse

  export type Func = (props: Props) => Promise<Response>
  export type Response = HttpClientModel.Response<ResponseData>
  export type ErrorResponse = HttpClientModel.ErrorResponse<ErrorResponseData>

  export interface ResolveWithDataResponse {
    type: ResponseType.ResolveWithData
    result: Result
  }
  export interface Result {
    statusCode: number
  }

  export enum ClientResponseTypeEnum {
    NotSet = 0,
    OFFER_SELECTED = 1,
    OFFER_CLOSE = 2,
    OFFER_DELAYED = 3,
    SYSTEM_EV_SELECTED = 4,
    SYSTEM_EV_CLOSE = 5,
    POP_UP_CLOSE = 6,
    POP_UP_OPEN = 7,
    POP_UP_DELAYED = 8,
    WIDGET_OPEN = 9,
    WIDGET_CLOSE = 10,
    WIDGET_DELAYED = 11,
    WIDGET_SHOW = 12,
  }

  export type ResponseData = ResolveWithDataResponse | ResolveWithSystemErrorResponse

  export type ErrorResponseData = RejectedWithSystemErrorResponse
}
