import { useClientProductsQuery } from '@/api/products/clientProducts'
import { useWidgetsSettingsQuery } from '@/api/products/widgetsSettings'
import { useAppContext } from '@/core'
import { AppContext } from '@/models/AppContext'
import { creditProductsWithWigdetSettingsSelector } from '@/selectors/creditProductsWithWigdetSettingsSelector'

export const useCreditProducts = () => {
  const context = useAppContext<AppContext>()

  const creditLoans = useClientProductsQuery({
    apiVersion: context.config.apiVersion,
  })
  const widgetsSettings = useWidgetsSettingsQuery({
    enabled: !context.config.features.hideDevelopmentInProgress,
  })

  return creditProductsWithWigdetSettingsSelector({
    creditLoans,
    widgetsSettings,
  })
}
