import React from 'react'
import styled from '@emotion/styled'
import { Shimmer } from '@platform-ui/components/Shimmer'

const TitleShimmer = styled(Shimmer)(() => ({
  marginTop: '12px',
  height: '28px',
  width: '140px',
  borderRadius: '20px',
}))

const Container = styled.section(({ theme }) => ({
  marginBottom: '32px',

  [`@media (min-width: ${theme.breakpoint.p1})`]: {
    marginBottom: '40px',
  },
}))

const Title = styled.h3(({ theme }) => ({
  paddingLeft: '12px',
  ...theme.typography['headline/20'],
  marginBottom: '20px',

  [`${theme.media.md}`]: {
    ...theme.typography['headline/28'],
    marginBottom: '20px',
  },
}))

const List = styled.div(() => ({
  margin: 0,
  padding: 0,
  listStyle: 'none',
}))

const Item = styled.li(({ theme }) => ({
  padding: 0,
  margin: 0,

  '&:not(:last-child)': {
    marginBottom: '16px',
  },
}))

export type ProductListProps = Readonly<{
  children: React.ReactNode
  className?: string
  title: string
  isLoading?: boolean
}>

export const ProductList = (props: ProductListProps) => {
  const { className, title, children, isLoading = false, ...otherProps } = props

  const items = React.Children.map(children, (child, index) => <Item key={index}>{child}</Item>)

  return (
    <Container className={className} {...otherProps}>
      <Title>{isLoading ? <TitleShimmer /> : title}</Title>
      <List>{items}</List>
    </Container>
  )
}
