import styled from '@emotion/styled'
import { IconButton } from '@platform-ui/components/IconButton'

export const OfferContentBlocksContainer = styled.div<{}>(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  paddingBottom: '4px',
  [`${theme.media.md}`]: {
    borderBottomColor: 'lightgray',
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
  },
}))

export const CloseIconButton = styled(IconButton)(() => ({
  position: 'absolute',
  padding: 4,
  right: 16,
}))

export const ContentBlocks = styled.div<{}>(({ theme }) => ({
  paddingLeft: '24px',
  paddingRight: '24px',
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'auto',
  overflowX: 'auto',
  scrollbarWidth: 'thin',
  '&::-webkit-scrollbar': {
    width: '6px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'lightgray',
  },
}))

export const DialogHeaderWrapper = styled.div(({ theme }) => ({
  position: 'relative',
  height: 64,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',

  [`${theme.media.md}`]: {
    marginTop: 16,
  },
}))
