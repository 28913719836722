import { useQuery, UseQueryOptions } from 'react-query'
import { ApiManager, ApiManagerModel, HttpClient } from '@/core'
import { ApiState } from '@/models/ApiState'
import { OfferContentModel } from './OffersModel'
import { adapter } from './adapters/adapter'

export const offerContent: OfferContentModel.Func = async () => {
  const { path, control } = ApiManager.getState<ApiState, ApiManagerModel.ApiOption>(
    (state) => state.offers.getOfferContent,
    {} as ApiManagerModel.ApiOption
  )

  const requestData: OfferContentModel.RequestData = {
    templatesIds: [],
    displayScreensIds: [],
    screenGroupsIds: [],
  }

  const response = await HttpClient.post<
    OfferContentModel.ResponseData,
    void,
    OfferContentModel.RequestData,
    void,
    OfferContentModel.ErrorResponse
  >({
    url: path,
    data: requestData,
    responseAdapter: adapter,
    control,
  })

  return response
}

function offersKey() {
  return '/api/offers/getOfferContent'
}

export function useOfferContentQuery<
  T extends OfferContentModel.ResponseData = OfferContentModel.ResponseData
>(
  options: Omit<
    UseQueryOptions<OfferContentModel.ResponseData, OfferContentModel.ErrorResponse, T>,
    'queryKey' | 'queryFn'
  > = {}
) {
  const key = offersKey()
  const result = useQuery(key, (state) => offerContent().then((res) => res.response), {
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    ...options,
  })

  return result
}
