import React, { useEffect, useState } from 'react'
import { ProductList } from '../../components/ProductList'
import { ProductShimmer } from '../../components/ProductShimmer'
import { ApiStatus } from '@/core'
import { ProductError } from '../../components/ProductError'
import { observer } from 'mobx-react-lite'
import { ProductItemOffer } from '../ProductItemOffer'
import { useOfferProducts } from '../../hooks/useOfferProducts'
import { useOfferContent } from '../../hooks/useOfferContent'
import { useSettingsQuery } from '@/api/settings/settings'
import { ResponseType } from '@/models/ResponseType'
import { MarketplaceItemOffer } from '../MarketplaceItemOffer/MarketplaceItemOffer'

export type ProductListOfferProps = {
  className?: string
}

export const ProductListOffer = observer(({ className }: ProductListOfferProps): JSX.Element => {
  const { data: settingsData } = useSettingsQuery()
  const isOffersEnable =
    settingsData?.type === ResponseType.ResolveWithData && settingsData.result.enableV3Offers

  const {
    error,
    productIds,
    products,
    marketplaceIds,
    marketplaceOffers,
    status,
    retry,
    hasAnyResult,
  } = useOfferProducts()
  useOfferContent()

  const [isLoading, setIsLoading] = useState(!hasAnyResult)

  useEffect(() => {
    if (hasAnyResult) {
      setIsLoading(false)
    }
  }, [hasAnyResult])

  if (status === ApiStatus.Idle || (error && error.type === 'IS_EMPTY_PRODUCTS')) {
    return null
  }

  if (!isOffersEnable) {
    return null
  }

  return (
    <ProductList title="Предложения" isLoading={isLoading} className={className}>
      {productIds.map((productId, i) => {
        const item = products[productId]

        return <ProductItemOffer product={item} />
      })}
      {marketplaceIds.map((productId, i) => {
        const item = marketplaceOffers[productId]

        return <MarketplaceItemOffer key={item.id} product={item} />
      })}
      {status === ApiStatus.Pending ? (
        <ProductShimmer />
      ) : (
        error && (
          <ProductError message="При получении предложений произошла ошибка." retryClick={retry} />
        )
      )}
    </ProductList>
  )
})
