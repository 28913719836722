import { ClientProductsMFOModel } from '@/api/products/clientProductsMFO'
import { useClientProductsMFOQuery } from '@/api/products/clientProductsMFO/clientProductsMFO'
import { useGetContractInfoMFOQuery } from '@/api/products/getContractInfoMFO/getContractInfoMFO'
import {
  GetCreditLinesMFOModel,
  useGetCreditLinesMFOQuery,
} from '@/api/products/mfo/getCreditLinesMFO'
import { useSettingsQuery } from '@/api/settings/settings'
import { ResponseType } from '@/models/ResponseType'
import { sortMFOContractsByStatus } from '../containers/ProductListMFO/utils'
import { sortMFOCreditLinesByStatus } from '../containers/ProductListMFO/utils/sortMFOCreditLinesByStatus'

export const useMFOProducts = () => {
  const settings = useSettingsQuery()

  const enableProductsMfo =
    settings.data?.type == ResponseType.ResolveWithData
      ? settings.data.result.enableProductsMfo
      : false

  const clientProductsMFO = useClientProductsMFOQuery(
    {
      ReturnCachedData: false,
    },
    {
      enabled: enableProductsMfo,
    }
  )

  const hasContracts =
    clientProductsMFO?.data?.type === ResponseType.ResolveWithData
      ? !!clientProductsMFO.data.Contracts.length &&
        clientProductsMFO.data.Contracts.some(
          (contract) => contract.Status !== ClientProductsMFOModel.StatusCode.NotSet
        )
      : false

  const contractInfoMFO = useGetContractInfoMFOQuery({
    enabled: hasContracts,
  })

  const contracts =
    contractInfoMFO?.data?.type === ResponseType.ResolveWithData
      ? contractInfoMFO.data.Contracts
      : []

  const contractMFOServerDate =
    contractInfoMFO?.data?.type === ResponseType.ResolveWithData
      ? contractInfoMFO.data.ServerDate
      : ''

  const hasCreditLines =
    clientProductsMFO?.data?.type === ResponseType.ResolveWithData
      ? !!clientProductsMFO.data.CreditLines?.length &&
        clientProductsMFO.data.CreditLines.some(
          (contract) => contract.status !== GetCreditLinesMFOModel.StatusCode.NotSet
        )
      : false

  const creditLinesInfo = useGetCreditLinesMFOQuery({
    enabled: hasCreditLines,
  })

  const creditLines =
    creditLinesInfo?.data?.type === ResponseType.ResolveWithData
      ? creditLinesInfo.data.creditLinesInfo
      : []

  const creditLinesServerDate =
    creditLinesInfo?.data?.type === ResponseType.ResolveWithData
      ? creditLinesInfo.data.serverDate
      : ''

  return {
    contractInfoMFOLoading:
      settings.isLoading || clientProductsMFO.isLoading || contractInfoMFO.isLoading,
    creditLinesInfoLoading:
      settings.isLoading || clientProductsMFO.isLoading || creditLinesInfo.isLoading,
    contracts: sortMFOContractsByStatus(contracts),
    contractMFOServerDate,
    creditLinesServerDate,
    contractInfoMFOError: clientProductsMFO.isError || contractInfoMFO.isError,
    creditLinesInfoError: clientProductsMFO.isError || creditLinesInfo.isError,
    refetchContractInfo: contractInfoMFO.refetch,
    refetchCreditLinesInfo: creditLinesInfo.refetch,
    creditLines: sortMFOCreditLinesByStatus(creditLines),
  }
}
