import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { ProductList } from '../../components/ProductList'
import { ProductShimmer } from '../../components/ProductShimmer'
import { ProductsModel } from '@/api/products/products'
import { ProductItemDebitCard } from '../ProductItemDebitCard'
import { ProductItemCreditCard } from '../ProductItemCreditCard'
import { ProductItemInstalmentCard } from '../ProductItemInstalmentCard'
import { AlienCardsModel } from '@/api/products/alienCards'
import { ApiStatus, useAppContext } from '@/core'
import { ProductError } from '../../components/ProductError'
import { observer } from 'mobx-react-lite'
import { ProductItemAlienCard } from '../ProductItemAlienCard'
import { useWidetsSettingsStore } from '../../widgetsSettingsStore/WidgetsSettingsStore'
import { useWalletProducts } from '../../hooks/useWalletProducts'
import { AppContext } from '@/models/AppContext'
import { ProductInDevelopmentDialog } from '../../components/ProductInDevelopmentDialog'
import { CashbackMainWidget } from '../../../partners/containers/CashbackMainWidget'
import { useSettingsQuery } from '@/api/settings/settings'
import { ResponseType } from '@/models/ResponseType'
import { usePolzaCards } from '../../hooks/usePolzaCards'
import { ApprovalCardSign } from '../../components/ApprovalCardSign'
import { useGetApprovalContracts } from '@/features/product-lists/selectors/approvalContractsSelector'
import { formatDate } from '@/utils/formatDate'
import { RoundCheckIcon } from '@platform-ui/icons'

export type ProductListWalletProps = {
  className?: string
}

export const DescriptionWrapper = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
}))

export const IconWrapper = styled.div(() => ({
  marginRight: '4px',
}))

export const ProductListWallet = observer(({ className }: ProductListWalletProps): JSX.Element => {
  const { products, status, error, retry, visibleProductsIds, hasAnyResult } = useWalletProducts()
  const {
    approvalCreditCards,
    approvalDebitCards,
    status: contractStatus,
  } = useGetApprovalContracts(3)
  const context = useAppContext<AppContext>()

  const { data: settingsData } = useSettingsQuery()

  const isHideDevelopmentInProgress = context.config.features.hideDevelopmentInProgress

  const widgetsSettings = useWidetsSettingsStore()
  const widgetsSettingsMap = widgetsSettings.getSettingsMap
  const isEdit = widgetsSettings.isEdit

  const [isLoading, setIsLoading] = useState(!hasAnyResult)
  const [isOpenModal, setIsOpenModal] = useState(false)

  const { hasPolzaCards } = usePolzaCards()
  const isCashbackEnabled =
    settingsData?.type === ResponseType.ResolveWithData &&
    settingsData.result.enablePolzaMainWidget &&
    hasPolzaCards

  useEffect(() => {
    /**
     * Требуется показывать заголовок если данные ранее уже были получены
     */
    if (hasAnyResult) {
      setIsLoading(false)
    }
  }, [hasAnyResult])

  if (
    status === ApiStatus.Idle ||
    (error &&
      error.type === 'IS_EMPTY_PRODUCTS' &&
      !approvalCreditCards.length &&
      !approvalDebitCards.length &&
      contractStatus !== 'pending') ||
    (status === ApiStatus.Fulfilled &&
      visibleProductsIds.length === 0 &&
      !approvalCreditCards.length &&
      !approvalDebitCards.length &&
      contractStatus !== 'pending')
  ) {
    return null
  }

  return (
    <ProductList title="Кошелёк" isLoading={isLoading} className={className}>
      {visibleProductsIds.map((productId, i) => {
        const item = products[productId]
        if (item.productType === ProductsModel.ProductType.DebitCard) {
          return (
            <ProductItemDebitCard
              key={item.id}
              product={item}
              isEdit={isEdit}
              isHidden={widgetsSettingsMap[item.settingWidgetId]?.isHidden}
              toggleHidden={() => widgetsSettings.toggleHidden(item)}
            />
          )
        }
        if (item.productType === ProductsModel.ProductType.CreditCard) {
          return (
            <ProductItemCreditCard
              key={item.id}
              product={item}
              isEdit={isEdit}
              isHidden={widgetsSettingsMap[item.settingWidgetId]?.isHidden}
              toggleHidden={() => widgetsSettings.toggleHidden(item)}
            />
          )
        }

        if (item.productType === AlienCardsModel.ProductType.AlienCard) {
          return (
            <ProductItemAlienCard
              key={item.id}
              product={item}
              isEdit={isEdit}
              isHidden={widgetsSettingsMap[item.settingWidgetId]?.isHidden}
              toggleHidden={() => widgetsSettings.toggleHidden(item)}
              onClick={() => isHideDevelopmentInProgress && setIsOpenModal(true)}
              isHideDevelopmentInProgress={isHideDevelopmentInProgress}
            />
          )
        }

        return (
          <ProductItemInstalmentCard
            key={item.id}
            product={item}
            isEdit={isEdit}
            isHidden={widgetsSettingsMap[item.settingWidgetId]?.isHidden}
            toggleHidden={() => widgetsSettings.toggleHidden(item)}
            onClick={() => isHideDevelopmentInProgress && setIsOpenModal(true)}
            isHideDevelopmentInProgress={isHideDevelopmentInProgress}
          />
        )
      })}

      {!!approvalDebitCards.length &&
        approvalDebitCards.map((card) => (
          <ApprovalCardSign
            cardType="debit"
            key={card.ContractNumber}
            name={card?.DebitCardDetail.DebitCardName}
            description={'Дебетовая карта'}
            contractNumber={card.ContractNumber}
            product={card.DebitCardDetail.DebitCardCode}
            info={`${card.WidgetHeader} до ${formatDate(
              new Date(card.RequestExpiry),
              'DD.MM.YYYY'
            )}`}
            bgColor={card.bgColor}
            bgColorTheme={card.bgColorTheme}
          />
        ))}
      {!!approvalCreditCards.length &&
        approvalCreditCards.map((card) => (
          <ApprovalCardSign
            cardType="credit"
            key={card.ContractNumber}
            name={card.ProductName}
            description={
              card.ProductSubtype === 'HomeCard' ? (
                <DescriptionWrapper>
                  <IconWrapper>
                    <RoundCheckIcon size="s10" />
                  </IconWrapper>{' '}
                  Одобрено
                </DescriptionWrapper>
              ) : (
                'Кредитная карта'
              )
            }
            contractNumber={card.ContractNumber}
            amount={card.CardDetail.CardLimit}
            info={`${card.WidgetHeader} до ${formatDate(
              new Date(card.RequestExpiry),
              'DD.MM.YYYY'
            )}`}
            bgColor={card.bgColor}
            bgColorTheme={card.bgColorTheme}
          />
        ))}

      {isCashbackEnabled && <CashbackMainWidget />}

      {status === ApiStatus.Pending ? (
        <ProductShimmer />
      ) : (
        error &&
        error.type !== 'IS_EMPTY_PRODUCTS' && (
          <ProductError message="При получении части карт произошла ошибка." retryClick={retry} />
        )
      )}

      <ProductInDevelopmentDialog isOpen={isOpenModal} onClose={() => setIsOpenModal(false)} />
    </ProductList>
  )
})
