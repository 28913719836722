import styled from '@emotion/styled'

export const BlockHtmlTitle = styled.div<{}>(({}) => ({
  fontSize: '20px',
  fontWeight: '700',
}))

export const BlockHtmlText = styled.div<{}>(({}) => ({
  fontSize: '16px',
  marginTop: '12px',
  marginBottom: '32px',
}))
