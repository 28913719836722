import React from 'react'
import { DialogHeaderWrapper, OfferProcessContainer } from './styled'
import { CloseIconButton } from '../OfferContentBlock/styled'
import { ClearIcon } from '@platform-ui/icons'
import { useSaveResponseQuery } from '@/api/approvalContracts/saveResponse'

export type OfferProcessProps = {
  processUrl: string
  onClose?: () => void
}

export const OfferProcess = ({ processUrl, onClose }: OfferProcessProps) => {
  useSaveResponseQuery({ Type: 9 })

  return (
    <OfferProcessContainer>
      <DialogHeaderWrapper>
        <CloseIconButton onClick={onClose}>
          <ClearIcon color="text/main" />
        </CloseIconButton>
      </DialogHeaderWrapper>
      <iframe width="100%" height="100%" src={processUrl} frameBorder="0"></iframe>
    </OfferProcessContainer>
  )
}
