import { useAppContext } from '@/core'
import { AppContext } from '@/models/AppContext'
import { useDcrUrlMutation } from '../../api/products/offerContent/dcrUrl/dcrUrl'
import { DcrUrlModel } from '../../api/products/offerContent/dcrUrl/DcrUrlModel'
import { useState } from 'react'
import { OffersModel } from '../../api/products/offers/OffersModel'
import { Q5fUrlModel } from '../../api/products/offerContent/q5fUrl/Q5fUrlModel'
import { useQ5fUrlMutation } from '../../api/products/offerContent/q5fUrl/q5fUrl'

export const useFormState = () => {
  const context = useAppContext<AppContext>()
  const dcrUrl = useDcrUrlMutation()
  const q5fUrl = useQ5fUrlMutation()

  const [storeUrl, setStoreUrl] = useState('')

  const handleSubmitDCR = async (params: DcrUrlModel.Props) => {
    try {
      let dcrUrlResponse = await dcrUrl.mutateAsync({
        product: params.product,
      })
      setStoreUrl(dcrUrlResponse.data.url)
    } catch (error) {
      const err: DcrUrlModel.ErrorResponse = error
      if (err.data) {
        context.toastify.error(err.data.systemErrors.map((item) => item.message).join(', '))
      }

      return
    }
  }

  const handleSubmitQ5F = async (params: Q5fUrlModel.Props) => {
    try {
      let q5fUrlResponse = await q5fUrl.mutateAsync({
        productType: params.productType,
        canal: params.canal,
        systemType: params.systemType,
        appVersion: params.appVersion,
        productIdent: params.productIdent,
      })
      setStoreUrl(q5fUrlResponse.data.url)
    } catch (error) {
      /**
       * Обработка сетевой ошибки
       */
      const err: Q5fUrlModel.ErrorResponse = error
      if (err.data) {
        context.toastify.error(err.data.systemErrors.map((item) => item.message).join(', '))
      }

      return
    }
  }

  const handleClick = (params: OffersModel.Details) => {
    if (params.channel == 'DCR') {
      handleSubmitDCR({ product: params.debitCardCode })
    }
    if (params.channel == 'Q5F') {
      handleSubmitQ5F({
        productType: params.productType,
        canal: params.channelNumber.toString(),
        systemType: 'web',
        appVersion: 'web',
        productIdent: params.productIdentTW,
      })
    }
  }

  return {
    handleClick,
    storeUrl,
  }
}
