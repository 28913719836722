import { useMFOProducts } from '../../hooks/useMFOProducts'
import { CreditContainer } from './Credit/CreditContainer'
import { CreditLineContainer } from './CreditLine/CreditLineContainer'

export const ProductListMFO = () => {
  const {
    contracts,
    contractInfoMFOLoading,
    contractInfoMFOError,
    contractMFOServerDate,
    creditLinesServerDate,
    creditLines,
    creditLinesInfoError,
    creditLinesInfoLoading,
    refetchCreditLinesInfo,
    refetchContractInfo,
  } = useMFOProducts()

  return (
    <>
      <CreditContainer
        contracts={contracts}
        loading={contractInfoMFOLoading}
        error={contractInfoMFOError}
        serverDate={contractMFOServerDate}
        refetchContractInfo={() => refetchContractInfo()}
      />
      <CreditLineContainer
        contracts={creditLines}
        loading={creditLinesInfoLoading}
        error={creditLinesInfoError}
        serverDate={creditLinesServerDate}
        refetchContractInfo={() => refetchCreditLinesInfo()}
      />
    </>
  )
}
