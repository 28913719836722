import { ResponseType } from '@/models/ResponseType'
import { createSelector } from 'reselect'
import { UseQueryResult } from 'react-query'
import { SystemError } from '@/models/SystemError'
import { ApiStatus } from '@/core'
import _keyBy from 'lodash/keyBy'
import { OffersModel } from '@/api/products/offers'

type State = {
  offers: UseQueryResult<OffersModel.ResponseData, OffersModel.ErrorResponse>
}
export const offerProductsSelector = createSelector(
  (state: State) => state.offers.data,
  (state: State) => state.offers.status,
  (state: State) => state.offers.refetch,
  (
    offersResponse,
    offersStatus,
    offersRefetch
  ): {
    error: SystemError<'NETWORK' | 'PARTIAL_RESPONSE' | 'IS_EMPTY_PRODUCTS'> | null
    productIds: string[]
    products: Record<string, OffersModel.OfferWidget>
    offerDetails: Record<string, OffersModel.OfferDetail>
    marketplaceIds: string[]
    marketplaceOffers: Record<string, OffersModel.MarketplaceOffer>
    status: ApiStatus
    retry: () => void
    hasAnyResult: boolean
  } => {
    const retryFns: (() => void)[] = []

    let products: OffersModel.Product[] = []
    let marketplaceOffers: OffersModel.MarketplaceOffer[] = []

    let hasNetworkError = false
    let hasPartialResponse = false
    let status = ApiStatus.Idle

    if (offersStatus === 'success' || offersStatus === 'error') {
      status = ApiStatus.Fulfilled
    }

    if (offersStatus === 'loading') {
      status = ApiStatus.Pending
    }

    if (offersStatus === 'error') {
      hasNetworkError = true
    }

    if (offersStatus === 'error') {
      retryFns.push(offersRefetch)
    }

    if (offersResponse) {
      if (offersResponse.type === ResponseType.ResolveWithData) {
        products = products.concat(...offersResponse.products)
        marketplaceOffers = marketplaceOffers.concat(...offersResponse.marketplaceOffers)
      } else {
        hasPartialResponse = true
        retryFns.push(offersRefetch)
      }
    }

    const error: SystemError<'NETWORK' | 'PARTIAL_RESPONSE' | 'IS_EMPTY_PRODUCTS'> | null =
      hasNetworkError
        ? {
            type: 'NETWORK',
            message: 'При получении данных произошла ошибка',
          }
        : hasPartialResponse
        ? {
            type: 'PARTIAL_RESPONSE',
            message: 'Ведутся сервисные работы',
          }
        : status === ApiStatus.Fulfilled && !products.length
        ? {
            type: 'IS_EMPTY_PRODUCTS',
            message: 'Список продуктов пуст',
          }
        : null

    let productWidgets: OffersModel.Widgets[] = []
    products.map((product) =>
      product.widgets
        .filter((w) => w.displayScreens.find((d) => d.id == 11))
        .forEach((widget) => productWidgets.push(widget))
    )

    let offerDetails = products.map((product) => product.details)
    const hasProducts = products.length > 0
    const productWidgetsMap = _keyBy(productWidgets, function (x) {
      return x.id
    })
    const offerDetailMap = _keyBy(offerDetails, function (x) {
      return x.templateId
    })

    let marketplaceWidgets: OffersModel.MarketplaceWidget[] = []
    marketplaceOffers
      .filter((w) => w.displayScreens.find((d) => d.id == 11))
      .map((offer: OffersModel.MarketplaceOffer) => {
        marketplaceWidgets.push(offer.widget)
      })
    const marketplaceWidgetsMap = _keyBy(marketplaceWidgets, function (x) {
      return x.id
    })

    return {
      error: error,
      products: productWidgetsMap,
      productIds: productWidgets.map((item) => item.id.toString()),
      offerDetails: offerDetailMap,
      marketplaceIds: marketplaceWidgets.map((item) => item.id.toString()),
      marketplaceOffers: marketplaceWidgetsMap,
      status,
      retry: () => {
        retryFns.forEach((item) => {
          item()
        })
      },
      hasAnyResult: hasProducts || !!error,
    }
  }
)
