import styled from '@emotion/styled'

export const BlockPartnersTitle = styled.div<{}>(({}) => ({
  fontSize: '20px',
  fontWeight: '700',
  marginBottom: '8px',
}))

export const BlockPartnersContentContainer = styled.div<{}>(({ theme }) => ({
  display: 'flex',
}))

export const BlockPartnersContentItem = styled.div<{}>(({ theme }) => ({
  width: '80px',
  height: '122px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}))

export const BlockPartnersContentIcon = styled.div<{ image: string }>(({ theme, image }) => ({
  width: '48px',
  height: '48px',
  background: `url(${image}) no-repeat`,
  backgroundSize: 'contain',
  marginBottom: '10px',
}))

export const BlockPartnersContentText = styled.div<{ textColor: string }>(
  ({ theme, textColor }) => ({
    fontSize: '12px',
    fontWeight: '600',
    color: textColor,
  })
)
