import { GetContractInfoMFOModel } from '@/api/products/getContractInfoMFO/GetContractInfoMFOModel'
import { GetCreditLinesMFOModel } from '@/api/products/mfo/getCreditLinesMFO'
import { IconWrapper } from '@/components/IconWrapper'
import { InfoSlot } from '../../../components/InfoSlot'
import { MFOPlate } from '@/features/product-lists/components/MFOPlate'
import { color } from '@platform-ui/core'
import { Attention2Icon, TransferByCashIcon } from '@platform-ui/icons'
import { FC, useState } from 'react'
import { getPaymentText } from '../utils'
import { Currency } from '@/components/Currency'
import { useRouter } from '@/next/router'
import { useAppContext } from '@/core'
import { AppContext } from '@/models/AppContext'
import { PaymentModal } from '../PaymentModal'

type Props = {
  contract: GetCreditLinesMFOModel.CreditLine
  serverDate: string
  refetchContractInfo: () => void
}

export const CreditLineItem: FC<Props> = ({ contract, serverDate, refetchContractInfo }) => {
  const { partnerInfo, nearestPayment, status, guiStatus, limit, type } = contract

  const router = useRouter()
  const context = useAppContext<AppContext>()

  const paymentText = getPaymentText(contract, serverDate)

  const paymentTextVisible = !(
    status === GetContractInfoMFOModel.StatusCode.Closed ||
    guiStatus === GetContractInfoMFOModel.GuiStatus.TooEarlyToPay ||
    guiStatus === GetContractInfoMFOModel.GuiStatus.NoPaymentSchedule ||
    guiStatus === GetContractInfoMFOModel.GuiStatus.PaymentInAdvance ||
    guiStatus === GetContractInfoMFOModel.GuiStatus.PaymentDebitedToday ||
    guiStatus === GetContractInfoMFOModel.GuiStatus.NotSet
  )

  const debt =
    guiStatus === GetContractInfoMFOModel.GuiStatus.OverduePayment ||
    status === GetContractInfoMFOModel.StatusCode.Demanded

  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <MFOPlate
        key={contract.contractId}
        name="Кредитный лимит"
        partnerLogoUrl={partnerInfo?.logoUrl || ''}
        amount={limit}
        currencyType="RUB"
        icon={
          <IconWrapper
            backgroundColor={color['icons/colors/brightTintTwenty']}
            textColor="text/tertiary"
          >
            <TransferByCashIcon color="icons/shape" size="s12" />
          </IconWrapper>
        }
        infoSlot={
          paymentTextVisible && paymentText ? (
            <InfoSlot
              icon={debt && <Attention2Icon color="icons/colors/error" size="s12" />}
              text={
                <>
                  {paymentText} <Currency amount={nearestPayment} type="RUB" />
                </>
              }
              buttonText="Внести платеж"
              buttonClick={() => {
                setIsOpen(true)
              }}
              textColor="text/tertiary"
            />
          ) : null
        }
        onClick={() => {
          router.push(context.routes.creditLine(contract?.contractId))
        }}
      />
      {isOpen && (
        <PaymentModal
          contractId={contract.contractId}
          amount={nearestPayment}
          isOpen={isOpen}
          onClose={() => {
            refetchContractInfo()
            setIsOpen(false)
          }}
          contractType={type}
          recipient={contract?.partnerInfo?.partnerName || ''}
        />
      )}
    </>
  )
}
