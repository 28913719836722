import React, { SyntheticEvent, useState } from 'react'
import { AboutSecurityIcon } from '@platform-ui/icons/AboutSecurityIcon'
import { LockIcon } from '@platform-ui/icons/LockIcon'
import { FreezeIcon } from '@platform-ui/icons/FreezeIcon'
import { StopIcon } from '@platform-ui/icons/StopIcon'
import { useAppContext } from '@/core'
import { AppContext } from '@/models/AppContext'
import { InfoSlot } from '../../components/InfoSlot'
import { ProductPlate } from '@/components/ProductPlate'
import { ProductsModel } from '@/api/products/products'
import { pluralize } from '@/utils/pluralize'
import { TimeIcon } from '@platform-ui/icons/TimeIcon'
import styled from '@emotion/styled'
import { CurrencyType } from '@/models/CurrencyType'
import { currency } from '@/utils/currency'
import { ProductItemWrapper } from '../../components/ProductItemWrapper'
import { ProductInDevelopmentDialog } from '../../components/ProductInDevelopmentDialog'
import { BaseModal } from '@platform-ui/components'
import { DialogFreezeCard } from '@/features/dialog-freeze-card'

const SumToPay = styled.span(() => ({
  whiteSpace: 'nowrap',
}))

const useInfoSlot = (
  product: ProductsModel.CreditCard,
  onClick: () => void,
  handleOpenFreezeCard: () => void
): {
  text: string | JSX.Element
  buttonText?: string
  buttonClick?: React.MouseEventHandler<HTMLButtonElement>
  icon?: React.ReactNode
} | null => {
  const { cardStatusDisplayed } = product

  const currencyComponent = (sum: number, type: CurrencyType) => (
    <SumToPay>{currency(sum, type).format()}</SumToPay>
  )

  if (cardStatusDisplayed === ProductsModel.CardStatusDisplayed.New) {
    return {
      text: 'Карта выпускается',
      icon: <TimeIcon color="icons/main" size="s8" />,
    }
  }
  if (cardStatusDisplayed === ProductsModel.CardStatusDisplayed.NotActive) {
    return {
      text: 'Карта не активирована',
      buttonText: 'Активировать карту',
      buttonClick: onClick,
      icon: <AboutSecurityIcon color="icons/main" size="s8" />,
    }
  }
  if (cardStatusDisplayed === ProductsModel.CardStatusDisplayed.Blocked) {
    return {
      text: 'Вы заблокировали карту',
      buttonText: 'Разблокировать',
      buttonClick: handleOpenFreezeCard,
      icon: <FreezeIcon color="icons/main" size="s8" />,
    }
  }
  if (cardStatusDisplayed === ProductsModel.CardStatusDisplayed.BlockedByBank) {
    return {
      text: 'Карта заблокирована банком',
      buttonText: 'Чат поддержки',
      buttonClick: onClick,
      icon: <LockIcon color="icons/main" size="s8" />,
    }
  }
  if (cardStatusDisplayed === ProductsModel.CardStatusDisplayed.Closed) {
    return {
      text: 'Карта закрыта',
      buttonText: 'Скрыть',
      buttonClick: onClick,
      icon: <StopIcon color="icons/main" size="s8" />,
    }
  }

  /**
   * Кредитная карта Homer
   */
  if (product.cardSubType === ProductsModel.CardSubType.HomerRevolver) {
    const { creditCardGuiStatus, daysLeft } = product.creditCardTWGuiData

    if (
      creditCardGuiStatus ===
      ProductsModel.CreditCardTWGuiStatus.PaymentRecommendedHirePurchaseBeforeMinPayment
    ) {
      return {
        text: (
          <>
            {`Платеж через ${daysLeft} ${pluralize(daysLeft, ['день', 'дня', 'дней'])} на сумму `}
            {currencyComponent(product.contract.properties.sumToPay, product.currencyType)}
          </>
        ),
        buttonText: 'Внести платёж',
        buttonClick: onClick,
      }
    }

    if (
      creditCardGuiStatus ===
      ProductsModel.CreditCardTWGuiStatus.PaymentDayHirePurchaseBeforeMinPayment
    ) {
      return {
        text: (
          <>
            {`Сегодня день платежа на сумму `}
            {currencyComponent(product.contract.properties.sumToPay, product.currencyType)}
          </>
        ),
        buttonText: 'Внести платёж',
        buttonClick: onClick,
      }
    }

    if (creditCardGuiStatus === ProductsModel.CreditCardTWGuiStatus.OverduePayment) {
      return {
        text: (
          <>
            Платеж на сумму{' '}
            {currencyComponent(product.contract.properties.sumToPay, product.currencyType)}
            {' просрочен'}
          </>
        ),
        buttonText: 'Внести платёж',
        buttonClick: onClick,
      }
    }

    return null
  }

  /**
   * Кредитная карта TW
   */

  const { creditCardTWPolzaGuiStatus, daysLeft } = product.creditCardTWGuiData

  if (
    creditCardTWPolzaGuiStatus ===
    ProductsModel.CreditCardTWPolzaGuiStatus.PaymentRecommendedBothPayments
  ) {
    return {
      text: (
        <>
          {`Платеж через ${daysLeft} ${pluralize(daysLeft, ['день', 'дня', 'дней'])} на сумму `}
          {currencyComponent(product.contract.properties.sumToPay, product.currencyType)}
        </>
      ),
      buttonText: 'Внести платёж',
      buttonClick: onClick,
    }
  }

  if (
    creditCardTWPolzaGuiStatus === ProductsModel.CreditCardTWPolzaGuiStatus.PaymentDayBothPayments
  ) {
    return {
      text: (
        <>
          {'Сегодня день платежа на сумму '}
          {currencyComponent(product.contract.properties.sumToPay, product.currencyType)}
        </>
      ),
      buttonText: 'Внести платёж',
      buttonClick: onClick,
    }
  }

  if (creditCardTWPolzaGuiStatus === ProductsModel.CreditCardTWPolzaGuiStatus.GracePaymentOnly) {
    return {
      text: (
        <>
          {'Минимальный платеж оплачен, остаток по кредиту '}
          {currencyComponent(product.contract.properties.sumToPay, product.currencyType)}
        </>
      ),
      buttonText: 'Внести платёж',
      buttonClick: onClick,
    }
  }

  if (creditCardTWPolzaGuiStatus === ProductsModel.CreditCardTWPolzaGuiStatus.OverduePayment) {
    return {
      text: (
        <>
          Платеж на сумму{' '}
          {currencyComponent(product.contract.properties.sumToPay, product.currencyType)}
          {' просрочен'}
        </>
      ),
      buttonText: 'Внести платёж',
      buttonClick: onClick,
    }
  }
  return null
}

export type ProductItemCreditCardProps = {
  className?: string
  product: ProductsModel.CreditCard
  isEdit?: boolean
  isHidden?: boolean
  toggleHidden: (widgetId: ProductsModel.CreditCard['settingWidgetId']) => void
}

export const ProductItemCreditCard: React.FC<ProductItemCreditCardProps> = (props) => {
  const { product, isEdit = false, isHidden = false, toggleHidden } = props
  const context = useAppContext<AppContext>()
  const [isOpenInDevelopmentModal, setIsOpenInDevelopmentModal] = useState(false)
  const [isOpenDialogFreezeCard, setIsOpenDialogFreezeCard] = useState(false)

  const isHideDevelopmentInProgress =
    product.cardStatusDisplayed !== ProductsModel.CardStatusDisplayed.Active &&
    product.cardStatusDisplayed !== ProductsModel.CardStatusDisplayed.Blocked
  const onClick = () => {
    if (isHideDevelopmentInProgress) {
      setIsOpenInDevelopmentModal(true)
    }
  }

  const handleOpenFreezeCard = () => {
    if (!isOpenDialogFreezeCard) {
      setIsOpenDialogFreezeCard(true)
    }
  }

  const infoSlotData = useInfoSlot(props.product, onClick, handleOpenFreezeCard)

  const onToggleHidden = (e: SyntheticEvent) => {
    e.preventDefault()
    toggleHidden(product.settingWidgetId)
  }

  return (
    <>
      <ProductItemWrapper
        href={context.routes.card(product.id)}
        isHideDevelopmentInProgress={isHideDevelopmentInProgress}
      >
        <ProductPlate
          onClick={onClick}
          name={product.productName}
          bgColor={product.bgColor}
          bgColorTheme={product.bgColorTheme}
          description={product.description}
          paymentSystem={product.paymentSystem}
          lastFourCardDigits={product.lastFourCardDigits}
          amount={product.availableBalance}
          currencyType={product.currencyType}
          infoSlot={infoSlotData && <InfoSlot {...infoSlotData} />}
          isEdit={isEdit}
          isHidden={isHidden}
          toggleHidden={onToggleHidden}
        />
      </ProductItemWrapper>
      <ProductInDevelopmentDialog
        isOpen={isOpenInDevelopmentModal}
        onClose={() => setIsOpenInDevelopmentModal(false)}
      />
      <BaseModal
        title={'Разблокировать карту'}
        onClose={() => setIsOpenDialogFreezeCard(!isOpenDialogFreezeCard)}
        isOpen={isOpenDialogFreezeCard}
      >
        <DialogFreezeCard
          cardStatus={product.cardStatusDisplayed}
          onClose={() => setIsOpenDialogFreezeCard(!isOpenDialogFreezeCard)}
          isFromModal={false}
          cardNumber={product.maskCardNumber}
          cardMBR={product.mbr}
          cardType={product.cardType}
        />
      </BaseModal>
    </>
  )
}
