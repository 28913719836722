import styled from '@emotion/styled'
import { IconButton } from '@platform-ui/components/IconButton'
import { Headline12 } from '@platform-ui/typography'

export const Root = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  textAlign: 'center',
  margin: '16px 0 40px',
}))

export const IconWrapper = styled(IconButton)(({ theme }) => ({
  background: theme.color['surface/secondary'],

  ':hover:not(:disabled), :active:not(:disabled), :focus:not(:disabled)': {
    background: theme.color['surface/secondary'],
  },
}))

export const Text = styled(Headline12)(({ theme }) => ({
  marginTop: 8,
  color: theme.color['text/primary'],
  cursor: 'pointer',
}))
