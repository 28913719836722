import React from 'react'
import {
  CloseIconButton,
  DialogButton,
  OfferContentErrorContainer,
  OfferContentErrorDesc,
  OfferContentErrorImage,
  OfferContentErrorInfo,
  OfferContentErrorText,
} from './styled'
import { ClearIcon } from '@platform-ui/icons'
import { DialogHeaderWrapper } from '../../styled'
import { Button } from '@platform-ui/components/Button'
import OfferContentErrorImg from '../../assets/OfferContentError.svg'
import Image from '@/next/image'

export type OfferContentErrorProps = {
  onClose?: () => void
}

export const OfferContentError = ({ onClose }: OfferContentErrorProps) => {
  return (
    <OfferContentErrorContainer>
      <DialogHeaderWrapper>
        <CloseIconButton onClick={onClose}>
          <ClearIcon color="text/main" />
        </CloseIconButton>
      </DialogHeaderWrapper>
      <OfferContentErrorInfo>
        <OfferContentErrorImage>
          <Image src={OfferContentErrorImg} />
        </OfferContentErrorImage>
        <OfferContentErrorText>Мы обновляем линейку продуктов</OfferContentErrorText>
        <OfferContentErrorDesc>Скоро здесь будут наши лучшие предложения</OfferContentErrorDesc>
      </OfferContentErrorInfo>
      <DialogButton>
        <Button isFullWidth onClick={onClose}>
          Вернуться назад
        </Button>
      </DialogButton>
    </OfferContentErrorContainer>
  )
}
