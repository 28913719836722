import { GetContractInfoMFOModel } from '@/api/products/getContractInfoMFO/GetContractInfoMFOModel'
import { GetCreditLinesMFOModel } from '@/api/products/mfo/getCreditLinesMFO'
import { getDateDiffInDays, pluralize } from '@/utils'

export const getPaymentText = (
  contract: GetContractInfoMFOModel.Contract | GetCreditLinesMFOModel.CreditLine,
  serverDate: string
) => {
  const { guiStatus, status, nearestDate } = contract

  if (guiStatus === GetContractInfoMFOModel.GuiStatus.PaymentStronglyRecommended) {
    const parsedServerDate = new Date(Date.parse(serverDate))
    const parsedNearestDate = new Date(Date.parse(nearestDate))

    const days = getDateDiffInDays(parsedNearestDate, parsedServerDate)

    return `Платёж через ${days} ${pluralize(days, ['день', 'дня', 'дней'])} на сумму`
  }

  if (guiStatus === GetContractInfoMFOModel.GuiStatus.PaymentDay) {
    return 'Сегодня день платежа на сумму'
  }

  if (
    guiStatus === GetContractInfoMFOModel.GuiStatus.OverduePayment ||
    status === GetContractInfoMFOModel.StatusCode.Demanded
  ) {
    return 'Погасить задолженность'
  }

  return ''
}
